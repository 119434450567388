import { isEmpty, cloneDeep } from 'lodash'
import { ref } from 'vue'
import hash from 'object-hash'
import { QueryElement as QueryRow, SavedQuery } from 'src/types/Query.types'
import QueryAPI from 'src/api/query'
import QueryUtils from 'src/utils/query'
import { isQueryValid as isValid } from 'components/project/analysis/results/query/utils'
import { GroupOrTheme } from 'src/pages/dashboard/Dashboard.utils'

type CacheKey = hash.NotUndefined

interface CacheOptions {
  cacheTime: number
}

interface CacheEntry {
  value: unknown
  expires: number
}

export type StagedQuery = QueryRow[]

export const fetchThemes = async (projectId: number, analysisId: number): Promise<SavedQuery[]> => {
  return QueryAPI.listSavedQueries(projectId, analysisId)
}

export const useLocalStorageState = () => {
  const stateToLocalStorage = (state: Record<string, unknown>) => {
    const base64 = window.btoa(JSON.stringify(state))
    localStorage.setItem('theme-builder-state', base64)
  }

  const localStorageToState = () => {
    const state = localStorage.getItem('theme-builder-state')
    if (state) {
      try {
        const decoded = window.atob(state)
        const parsed = JSON.parse(decoded)
        return parsed
      } catch {
        return {}
      }
    }
  }

  return {
    stateToLocalStorage,
    localStorageToState,
  }
}

export const makeKey = (obj: CacheKey): string => {
  return hash(JSON.stringify(obj), {
    algorithm: 'md5',
  })
}

export const useCache = (options?: CacheOptions) => {
  const config: CacheOptions = {
    cacheTime: Infinity,
    ...options,
  }

  const state = ref<Record<string, CacheEntry>>({})

  const add = (key: CacheKey, value: unknown) => {
    const keyHash = makeKey(key)
    state.value[keyHash] = {
      expires: Date.now() + config.cacheTime,
      value,
    }
  }

  const remove = (key: CacheKey) => {
    const keyHash = makeKey(key)
    delete state.value[keyHash]
  }

  const has = (key: CacheKey): boolean => {
    const keyHash = makeKey(key)
    const entry = state.value[keyHash]
    return !!(entry && entry.expires > Date.now())
  }

  const get = (key: CacheKey): unknown => {
    const keyHash = makeKey(key)
    const entry = state.value[keyHash]
    return entry?.value
  }

  return {
    state: state.value,
    add,
    remove,
    has,
    get,
  }
}

export const useFetchData = () => {
  const cache = useCache()

  const fetch = <T>(
    _key: CacheKey,
    fn: (...args: any[]) => PromiseLike<unknown>,
    params: any[] = [],
    force = false,
  ): Promise<T> => {
    const key = cloneDeep(_key)
    if (!force && cache.has(key)) {
      return Promise.resolve(cache.get(key)) as Promise<T>
    }

    return new Promise((resolve, reject) => {
      fn(...params).then((data) => {
        resolve(data as T)
        cache.add(key, data)
      }, reject)
    })
  }

  return {
    fetch,
  }
}

export const getQueryRows = (query_value: SavedQuery['query_value']) => {
  try {
    if (isEmpty(query_value)) return []
    return QueryUtils.botanicToQueryRows(query_value)
  } catch {
    return []
  }
}

export const isQueryValid = (rows: QueryRow[]) => {
  return rows.length > 0 && isValid(rows)
}

export const getContainedQueries = (savedQuery: SavedQuery, allSavedQueries: SavedQuery[]): SavedQuery[] => {
  const containedQueries: SavedQuery[] = []
  const findNestedQueries = (queryRows: QueryRow[]): void => {
    queryRows.forEach((row) => {
      if (row.type === 'query') {
        row.values?.forEach((nestedQueryIdStr) => {
          const nestedQueryId = parseInt(nestedQueryIdStr.toString(), 10)
          const nestedQuery = allSavedQueries.find((q) => q.id === nestedQueryId)

          if (nestedQuery) {
            containedQueries.push(nestedQuery)

            // Recursive check for more nested queries
            const nestedQueryRows = QueryUtils.botanicToQueryRows(nestedQuery.query_value)
            findNestedQueries(nestedQueryRows)
          }
        })
      }
    })
  }

  // Start with the provided savedQuery to find nested queries
  const initialQueryRows = QueryUtils.botanicToQueryRows(savedQuery.query_value)
  findNestedQueries(initialQueryRows)

  return containedQueries
}

// Generate a new theme name that is not already in use
export const makeNewThemeName = (themes: SavedQuery[]): string => {
  const themeNames = themes.map((t) => t.name)
  const newThemeName = 'New Theme'
  const newThemeNameWithNumber = (n: number) => `${newThemeName} ${n}`

  let i = 1
  while (themeNames.includes(newThemeNameWithNumber(i))) {
    i++
  }

  return newThemeNameWithNumber(i)
}

// Extract concepts from a query
export const getConceptsFromQuery = (query: SavedQuery): string[] => {
  const queryRows = QueryUtils.botanicToQueryRows(query.query_value)
  const concepts = queryRows.filter((row) => row.type === 'text')
  return concepts.flatMap((c) => c.values as string[])
}

interface NodeBase {
  id: string | number
  type: 'group' | 'theme'
  name: string
  children?: NodeBase[]
}

const matchParams = (node: NodeBase, params: Partial<NodeBase>): boolean => {
  const keys = Object.keys(params) as (keyof NodeBase)[]
  return keys.every((key) => params[key] === node[key])
}

export const findNode = <T extends NodeBase>(nodes: GroupOrTheme[], params: Partial<T>): T | undefined => {
  for (const node of nodes) {
    if (matchParams(node, params)) {
      return node as T
    }

    if (node.type === 'group' && node.children) {
      const found = findNode<T>(node.children, params)
      if (found) {
        return found
      }
    }
  }
}

export function findParentNode(nodes: GroupOrTheme[], params: Partial<GroupOrTheme>): GroupOrTheme | undefined {
  for (const node of nodes) {
    if (node.type == 'group' && node.children) {
      const foundChild = node.children.find((child) => matchParams(child, params))
      if (foundChild) {
        return node
      } else {
        // Recursively search children
        const foundInChild = findParentNode(node.children, params)
        if (foundInChild) return foundInChild
      }
    }
  }
}

export const isNodeContained = <T extends NodeBase>(
  nodes: GroupOrTheme[],
  params: Partial<T>,
  parentParams: Partial<T>,
): boolean => {
  const parentNode = findNode(nodes, parentParams)
  if (parentNode && parentNode.type === 'group' && parentNode.children) {
    return Boolean(findNode(parentNode.children as GroupOrTheme[], params))
  }

  return false
}

export interface CoverageNode {
  id: string | number
  name: string
  num_hits: number
  coverage: number
  type: 'theme' | 'group'
  children?: CoverageNode[]
}

export interface TreeDataNode extends CoverageNode {
  id: string
  themeCount?: number
  leaf: boolean
  updating: boolean
}

export type SortMode = 'name' | 'coverage'
export type SortOrder = 'asc' | 'desc'

export const sortQueryStats = (
  queryStats: CoverageNode[],
  mode: NonNullable<SortMode>,
  order: SortOrder,
): CoverageNode[] => {
  return queryStats
    .sort((a, b) => {
      const propA = a[mode]
      const propB = b[mode]

      let comparison = 0

      if (mode === 'name') {
        let valA = (propA as string).toLowerCase()
        let valB = (propB as string).toLowerCase()
        if (valA < valB) comparison = 1
        if (valA > valB) comparison = -1
      } else if (mode === 'coverage') {
        let valA = propA as number
        let valB = propB as number
        comparison = valA - valB
      }

      if (order === 'desc') {
        comparison *= -1
      }

      return comparison
    })
    .map((item) => {
      if (item.children) {
        return {
          ...item,
          children: sortQueryStats(item.children, mode, order),
        }
      }
      return item
    })
}

export interface NodeData {
  id: number
  type: 'group' | 'theme'
  label: string
  children?: NodeData[]
  key: string
}

// Map CoverageNode to TreeNode
export const mapGroupTreeToElTree = <T extends GroupOrTheme>(coverageNodes: T[]): NodeData[] => {
  return coverageNodes.reduce((arr, node) => {
    if (node.is_new) return arr

    const treeNode: NodeData = {
      label: node.name,
      id: Number(node.id),
      type: node.type,
      key: `${node.id}-${node.type}`,
    }
    if (node.type === 'group' && node.children) {
      treeNode.children = mapGroupTreeToElTree(node.children)
    }
    return arr.concat(treeNode)
  }, [] as NodeData[])
}
