<template>
  <div id="dashboard-wrapper" ref="dashboardWrapper">
    <!-- Automatically determines whether to display an error banner -->
    <error-banner></error-banner>

    <template v-if="!dashboardError && (!requiredStateIsAvailable || dashboardAwaiting)">
      <transition name="fade" leave-active-class="animated fadeOut">
        <page-loader :opacity="dashboardAwaiting ? 0.9 : 1">
          <slot>Fetching your Dashboard data...</slot>
        </page-loader>
      </transition>
    </template>

    <template v-else>
      <!-- Menu -->
      <div id="dashboardMenu" class="ui top huge inverted menu">
        <router-link class="item borderless logo" :to="{ name: 'home' }" active-class="never-active">
          <img class="ui image small" :src="kapiche_mark_inverse" />
        </router-link>

        <!-- Breadcrumb menu -->
        <template v-if="!dashboardError && inAnalysis">
          <div class="left menu breadcrumbs">
            <template v-for="(item, index) in breadcrumbItems" :key="index">
              <div class="item borderless chevron">
                <img class="chevron-img" :src="dashboard_chevron_right" />
              </div>
              <div class="item borderless breadcrumb">
                <span class="label-item">{{ item.labelItem }}</span>
                <router-link
                  v-if="item.condition()"
                  :to="item.routerLinkTo"
                  :class="[item.routerLinkClass, { masked: !loaded, unmasked: loaded }]"
                >
                  {{ item.routerLinkValue }}
                </router-link>
              </div>
            </template>
          </div>

          <div class="right menu">
            <template v-if="loggedIn">
              <router-link class="item borderless" :to="{ name: 'storyboard' }">
                <i class="kapiche-icon-chevron-left icon"></i> Back to analysis
              </router-link>
            </template>
          </div>
        </template>
        <div v-if="analystUser && !inAnalysis" class="analyst-view-message">
          <span class="analyst-view-message-text">
            Hey {{ currentUser.first_name }}, you have Creator access to the dashboard you are viewing:
          </span>
          <bf-button size="tiny" color="blue" @click="showExplorerMode"> GO TO CREATOR VIEW </bf-button>
        </div>
      </div>
      <!-- Debug panel-->
      <div v-if="featureFlags.dev_mode" style="display: flex; flex-direction: row; gap: 20px">
        <!-- Each of the divs below will be a separate panel, all arranged in a row. Each can-->
        <!-- scroll independently. This is a good place to display some internal state for-->
        <!-- dev work. Be careful with very large members like $data, which can be huge because-->
        <!-- of all the queries.-->
        <div style="max-height: 500px; overflow-y: auto">
          <pre>{{ JSON.stringify(breadcrumbItems, null, 2) }}</pre>
        </div>
        <div style="max-height: 500px; overflow-y: auto">
          <pre>queryId {{ JSON.stringify(queryId, null, 2) }}</pre>
          <pre>queryName {{ JSON.stringify(queryName, null, 2) }}</pre>
          <pre>dashboardType {{ JSON.stringify(dashboardType, null, 2) }}</pre>
          <pre>queryName {{ JSON.stringify(queryName, null, 2) }}</pre>
          <pre>currentConcepts {{ JSON.stringify(currentConcepts, null, 2) }}</pre>
          <pre>segment {{ JSON.stringify(segment, null, 2) }}</pre>
        </div>
      </div>
      <!-- Filters -->
      <control-bar
        v-if="requiredStateIsAvailable"
        class="filterBar"
        :dashboards="currentAnalysis.dashboards"
        :dashboard-id="currentDashboard.id"
        :dashboard-name="currentDashboard.name"
        :dashboard-type="dashboardType"
        :date-fields="dateFields"
        :default-date-field="defaultDateField"
        :week-start="currentDashboard.project.week_start"
        :is-loading="!loaded"
        :unsaved-changes="unsavedChanges"
        :viewer-mode="!inAnalysis"
        :dashboard-date-range="dashboardDateRange"
        :feature-flags="featureFlags"
        :schema="currentDashboard.project.schema"
        :group-by="groupBy"
        :compare-mode="compareModeView"
        :show-group-labels="showGroupLabels"
        @export-to-csv="exportCsv"
        @export-to-ppt="exportPpt"
        @show-edit-dashboard-modal="showEditDashboardModal"
        @show-delete-dashboard-modal="showDeleteDashboardModal"
        @show-digest-modal="showDigestModal"
        @show-share-modal="showShareModal"
        @show-queries-modal="showQueriesModal"
        @reset-dashboard="resetDashboard"
        @widget-config-updated="onWidgetConfigChange"
        @show-widget-modal="showWidgetConfigModal"
        @updated-date-range="setDateRange"
        @groupby-changed="updateGroupby"
        @compare-mode="compareMode = $event"
        @show-group-labels="showGroupLabels = $event"
      >
        <template #controls-left>
          <bf-button
            v-if="inAnalysis"
            size="tiny"
            color="blue"
            :class="{ masked: !loaded, unmasked: loaded }"
            :style="{ height: '30px' }"
            @click="showSaveAsModal"
          >
            SAVE AS...
          </bf-button>
          <el-popover v-if="unsavedChanges && inAnalysis" effect="dark" popper-class="wide" :hide-after="0">
            <div class="changes">
              <header>Save all changes made to this Dashboard. Details below:</header>
              <dashboard-changes-tooltip
                :filter-diff="filterDiff"
                :themes-diff="themesDiff"
                :date-range-diff="dateRangeDiff"
                :date-filters-changed="unsavedDateFilters"
                :modified-overview-widgets="widgetConfigDiff('overview')"
                :modified-drilldown-widgets="widgetConfigDiff('drilldown')"
                :group-by-diff="groupByDiff"
                :speaker-field-diff="speakerFieldDiff"
                :compare-mode-new-value="compareModeNewValue"
              />
            </div>
            <template #reference>
              <bf-button
                :class="['save-button', { masked: !loaded, unmasked: loaded }]"
                size="tiny"
                color="green"
                @click="saveDashboard"
              >
                SAVE
              </bf-button>
            </template>
          </el-popover>
          <el-popover v-if="unsavedChanges && inAnalysis" effect="dark" popper-class="wide" :hide-after="0">
            <div class="changes">
              <header>Discard all changes made to this Dashboard. Details below:</header>
              <dashboard-changes-tooltip
                :themes-diff="themesDiff"
                :filter-diff="filterDiff"
                :date-filters-changed="unsavedDateFilters"
                :date-range-diff="dateRangeDiff"
                :modified-overview-widgets="widgetConfigDiff('overview')"
                :modified-drilldown-widgets="widgetConfigDiff('drilldown')"
                :group-by-diff="groupByDiff"
                :speaker-field-diff="speakerFieldDiff"
                :compare-mode-new-value="compareModeNewValue"
              />
            </div>
            <template #reference>
              <bf-button
                class="clear"
                :class="{ masked: !loaded, unmasked: loaded }"
                size="tiny"
                color="transparent"
                @click="toggleDiscardAllModal(true)"
              >
                <icon color="#068ccc" name="revert" :size="16" /> DISCARD
              </bf-button>
            </template>
          </el-popover>
        </template>
      </control-bar>

      <div id="dashboardContent" ref="scrollableContent">
        <div v-if="dashboardError" class="error-box">
          <template v-if="[404, 403].includes(dashboardError.status)">
            <h1>Dashboard could not be found</h1>
            <p>This Dashboard either does not exist, or you do not have permission to access it.</p>
          </template>
          <template v-else>
            <h2>Unexpected Error</h2>
            <p>An unexpected error occurred when accessing this dashboard.</p>
          </template>
          <div class="text-box">
            <p>Try the following:</p>
            <ul>
              <li>If you arrived at this page by typing the in URL, double-check that the URL is correct</li>
              <li>
                Check with your site or project admin that you have been given permission to access this dashboard
              </li>
              <li>Check your internet connection &amp; refresh the page</li>
            </ul>
            <p>Please contact support if you cannot resolve this.</p>
          </div>
          <bf-button color="blue" size="large" :route-to="{ name: 'home' }"> Back to Kapiche </bf-button>
        </div>

        <!-- ERROR WHEN ACCESSING QUERY THAT ISN'T VIEWABLE ON THIS DASHBOARD -->
        <div v-else-if="viewedQueryNotOnDashboard" class="error-box">
          <h1>Theme could not be found</h1>
          <p>This dashboard does not include this theme, or the theme does not exist.</p>
          <bf-button color="blue" size="large" :route-to="routeToOverview"> Back to overview </bf-button>
        </div>

        <template v-else-if="requiredStateIsAvailable">
          <div class="filter-bars">
            <filter-bar
              ref="filterBar1"
              :query-id="0"
              :query-scope="currentDashboard.project.query_scope_default"
              :query-rows="selectedQueryRows"
              :allow-themes="false"
              :allow-concepts="false"
              :allow-operators="false"
              :allow-text="false"
              :location="location"
              :filter-warnings="filterWarnings"
              @set-query-rows="selectedQueryRows = $event"
              @collapse-change="syncFilterBarCollapse"
            >
              <template v-if="compareModeView" #title>
                <slice-rename
                  :initial-value="currentDashboard.slice1_name"
                  :submit="(value) => renameSlice('slice1_name', value)"
                />
              </template>
            </filter-bar>
            <filter-bar
              v-if="compareModeView"
              ref="filterBar2"
              :query-id="0"
              :query-scope="currentDashboard.project.query_scope_default"
              :query-rows="selectedCompareQueryRows"
              :allow-themes="false"
              :allow-concepts="false"
              :allow-operators="false"
              :allow-text="false"
              :location="location"
              :filter-warnings="filterWarnings"
              @set-query-rows="selectedCompareQueryRows = $event"
              @collapse-change="syncFilterBarCollapse"
            >
              <template v-if="compareModeView" #title>
                <slice-rename
                  :initial-value="currentDashboard.slice2_name"
                  :submit="(value) => renameSlice('slice2_name', value)"
                />
              </template>
            </filter-bar>
          </div>
          <router-view
            key="content"
            :dashboard-loading="!loaded"
            :group-by="groupBy"
            :speaker-field="speakerField"
            :query-rows="selectedQueryRows"
            :query-rows-compare="selectedCompareQueryRows"
            :compare-mode="compareModeView"
            :dashboard-type="dashboardType"
            @set-dashboard-type="setDashboardType"
            @scroll-to-top="scrollToTop"
            @toggleFilters="toggleFilters"
            @setDateFilters="setDateFilters"
            @speaker-field-change="speakerFieldChange"
            @set-ref="overviewRef = $event"
          />
        </template>
      </div>

      <template v-if="loggedIn && inAnalysis && !dashboardError">
        <modal :visible="queriesModalVisible" @close="hideQueriesModal">
          <template #header>
            <div>Select Themes to Display</div>
          </template>
          <template #content>
            <div class="query-modal">
              <p>Choose which Themes to use for this Dashboard.</p>
              <div v-if="availableQueries.value.length > 0" class="select-actions">
                <p class="select-action-item" @click="selectAllQueries">Select all</p>
                <p class="select-action-item" @click="deselectAllQueries">Deselect all</p>
              </div>
              <checkbox-tree
                v-if="themeGroupTree && availableQueries.value.length > 0"
                :tree-data="themeGroupTree"
                :checked="usedQueries"
                :truncate-labels="70"
                label="name"
                @checked-change="usedQueries = $event"
              />
              <p v-if="availableQueries.value.length === 0" class="muted">There are no Themes available.</p>
            </div>
          </template>
          <template #buttons>
            <button class="ui large button" @click="hideQueriesModal">Cancel</button>
            <button class="ui large blue button" @click="applyQueries">Apply</button>
          </template>
        </modal>

        <dashboard-sharing-modal
          :visible="shareModalVisible"
          @close="hideShareModal"
          @link-copied="onLinkCopy"
          @updated="updateDashboardMembers"
        />

        <email-digest-management-modal
          :visible="digestManagementModalVisible"
          @close="digestManagementModalVisible = false"
        />
        <dashboard-widgets-modal
          :visible="dashboardWidgetsModalVisible"
          @close="dashboardWidgetsModalVisible = false"
          @widgets-updated="onWidgetConfigChange"
        />

        <modal-confirm-prompt
          :visible="visibleDiscardAllModal"
          confirm-text="Discard Changes"
          title="Discard all changes?"
          @close="toggleDiscardAllModal(false)"
          @confirm="discardDashboardChanges"
        >
          <p>Discard all changes made to this dashboard?</p>
          <p>This action cannot be undone.</p>
        </modal-confirm-prompt>

        <!-- Edit dashboard modal-->
        <modal :visible="editDashboardModalVisible" @close="closeEditDashboardModal">
          <template #header>
            <div>Rename Dashboard</div>
          </template>
          <template #content>
            <VeeForm ref="editDashboardObserver" v-slot="{ meta, handleSubmit }">
              <form class="edit-dashboard" @submit.prevent="handleSubmit(saveEditDashboard)">
                <div class="form-body">
                  <Field
                    v-slot="{ field, errors }"
                    mode="aggressive"
                    rules="required|max:200"
                    vid="name"
                    name="Name"
                    tag="div"
                    class="input-section"
                  >
                    <label>
                      <span>Dashboard title</span>
                      <bf-text-input
                        v-model="editDashboardFormValues.name"
                        class="nameInput"
                        placeholder="Dashboard title"
                        :errors="errors"
                        focus
                        @input="field.onInput"
                      />
                    </label>
                  </Field>
                  <div class="buttons">
                    <bf-button type="button" size="big" @click="closeEditDashboardModal"> Cancel </bf-button>
                    <bf-button
                      type="submit"
                      color="blue"
                      size="big"
                      :disabled="
                        !meta.valid ||
                        meta.pending ||
                        isEditDashboardFormUpdating ||
                        // disable update if value has not changed
                        editDashboardFormValues.name === editDashboardInitialFormValues.name
                      "
                    >
                      Save
                    </bf-button>
                  </div>
                </div>
              </form>
            </VeeForm>
          </template>
        </modal>

        <!-- Save as dashboard modal-->
        <modal :visible="saveAsModalVisible" @close="closeSaveAsModal">
          <template #header>
            <div>Save new Dashboard as...</div>
          </template>
          <template #content>
            <VeeForm ref="saveAsObserver" v-slot="{ meta, handleSubmit }">
              <form class="save-as-dashboard" @submit.prevent="handleSubmit(saveNewDashboard)">
                <div class="form-body">
                  <Field
                    v-slot="{ field, errors }"
                    mode="aggressive"
                    rules="required|max:200"
                    vid="name"
                    name="Dashboard title"
                    tag="div"
                    class="input-section"
                  >
                    <label>
                      <span>Dashboard title</span>
                      <bf-text-input
                        v-bind="field"
                        :value="saveAsFormValues.name"
                        class="nameInput"
                        placeholder="Enter title"
                        :errors="errors"
                        focus
                        @input="updateFormValue"
                      />
                    </label>
                  </Field>
                  <div class="buttons">
                    <bf-button type="button" size="big" @click="closeSaveAsModal"> Cancel </bf-button>
                    <bf-button
                      type="submit"
                      color="blue"
                      size="big"
                      :disabled="!meta.valid || meta.pending || isSaveAsFormUpdating || !saveAsFormValues.name"
                    >
                      Save new Dashboard
                    </bf-button>
                  </div>
                </div>
              </form>
            </VeeForm>
          </template>
        </modal>

        <!-- Delete dashboard modal-->
        <modal :visible="deleteDashboardModalVisible" @close="closeDeleteDashboardModal">
          <template #header>
            <div>Delete Dashboard</div>
          </template>
          <template #content>
            <div class="delete-dashboard">
              <div class="info-msg">
                <p>
                  Delete "<strong>{{ currentDashboard.name }}</strong
                  >"?
                </p>
                <p>{{ usersWithAccess }} people (not including you) will lose access.</p>
                <p>This action cannot be undone.</p>
              </div>
              <div class="buttons">
                <bf-button size="big" @click="closeDeleteDashboardModal"> Cancel </bf-button>
                <bf-button color="red" size="big" :disabled="isDeleteDashboardPending" @click="deleteDashboard">
                  Delete
                </bf-button>
              </div>
            </div>
          </template>
        </modal>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import dayjs from 'dayjs'
import { isEqual, cloneDeep } from 'lodash'
import { computed, defineComponent, provide, reactive, ref, watch, toRef } from 'vue'
import { useRoute } from 'vue-router'
import { mapGetters, mapActions } from 'vuex'
import { defineRule, Form as VeeForm, Field } from 'vee-validate'

import kapiche_mark_inverse from 'assets/img/dashboards/kapiche_mark_inverse.svg'
import dashboard_chevron_right from 'assets/img/dashboards/dashboard-chevron-right.svg'
import ErrorBanner from 'components/widgets/ErrorBanner.vue'
import ControlBar from 'components/widgets/FilterBar/ControlBar.vue'
import Modal from 'components/Modal.vue'
import { BfTextInput, BfButton } from 'components/Butterfly'
import PageLoader from 'components/widgets/PageLoader.vue'
import Query, { ThemeGroup } from 'src/api/query'
import QueryUtils from 'src/utils/query'
import Util from 'src/utils/general'
import {
  LOAD_PROJECT,
  LOAD_ANALYSIS,
  LOAD_DASHBOARD,
  UPDATE_DASHBOARD,
  SET_DASHBOARD_QUERIES,
  SET_ERRORS,
  SET_DASHBOARD,
  CLEAR_PROJECT,
  SET_WIDGET_CONFIG,
  DISCARD_DASHBOARD_CONFIG_CHANGES,
  SAVE_DASHBOARD_DATE_RANGE,
} from 'src/store/types'
import { useStore } from 'src/store'
import DashboardSharingModal from 'components/project/analysis/results/dashboards/DashboardSharingModal.vue'
import EmailDigestManagementModal from 'components/widgets/EmailDigest/EmailDigestManagementModal.vue'
import { ResizeSensor } from 'css-element-queries/src/ResizeSensor'
import { ToggleFilterType } from 'types/DashboardFilters.types'
import {
  Group,
  GroupOrTheme,
  generateConfig,
  processQueries,
  findUsedThemeGroups,
  useBreadcrumbItems,
  DrilldownSegment,
} from './Dashboard.utils'
import ProjectAPI from 'src/api/project'
import DashboardWidgetsModal from 'components/widgets/DashboardWidgetsModal/DashboardWidgetsModal.vue'
import ModalConfirmPrompt from 'components/widgets/ModalConfirmPrompt/ModalConfirmPrompt.vue'
import { DashboardConfig, DashboardWidgetConfigDiff, DateRangeTypeEnum, Dashboard } from 'types/DashboardTypes'
import DashboardChangesTooltip from 'components/widgets/DashboardChangesTooltip/DashboardChangesTooltip.vue'
import { QueryRow, QueryLocation, SavedQuery } from 'src/types/Query.types'
import Icon from 'components/Icon.vue'
import FilterBar, { FilterWarning } from 'components/project/analysis/results/ThemeBuilder/FilterBar.vue'
import { applyToggledSegmentsToQueryRows } from 'src/types/utils'
import SliceRename from './SliceRename.vue'
import { dateRangeToFilter } from 'src/utils/dates'
import { isQueryValid } from 'components/project/analysis/results/query/utils'
import CheckboxTree from 'components/CheckboxTree.vue'
import { getFilterDiff, getThemesDiff, getWidgetConfigDiff } from '../trial/Workbench/Workbench.utils'

// field max length validator: eg. `rules="max:200"`
defineRule('max', (value, max) => {
  return value.length > max ? `Your text is too long (${value.length}).   Maximum length is ${max}.` : true
})

export default defineComponent({
  components: {
    DashboardChangesTooltip,
    DashboardWidgetsModal,
    ModalConfirmPrompt,
    ErrorBanner,
    Modal,
    PageLoader,
    DashboardSharingModal,
    ControlBar,
    BfTextInput,
    BfButton,
    VeeForm,
    Field,
    EmailDigestManagementModal,
    Icon,
    FilterBar,
    SliceRename,
    CheckboxTree,
  },
  props: {
    projectId: { type: Number, required: false, default: null },
    analysisId: { type: Number, required: false, default: null },
    dashboardId: { type: [Number, String], required: true },
    inAnalysis: { type: Boolean, required: true },
    queryId: { type: Number, required: false, default: null },
  },
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const featureFlags = computed<Record<string, boolean>>(() => store.getters['featureFlags'])
    const themeGroups = computed<Group[]>(() => store.getters['themeGroups'])
    const savedQueries = computed<SavedQuery[]>(() => store.getters['savedQueries'])
    const themeToGroupNameMapById = computed<Group[]>(() => store.getters['themeToGroupNameMapById'])
    const groupToGroupNameMapById = computed<Group[]>(() => store.getters['groupToGroupNameMapById'])
    const currentDashboard = computed<Dashboard>(() => store.getters['currentDashboard'])
    const themeGroupTree = ref<GroupOrTheme[] | null>(null)
    const showGroupLabels = ref<boolean>(false)
    const usedQueries = ref<number[]>([])

    const availableQueries = reactive<{ value: SavedQuery[] }>({ value: [] })

    const themeNameMap = computed(() => {
      const queries = savedQueries.value
      return queries.reduce(
        (p, c) => {
          p[c.id] = c.name
          return p
        },
        {} as Record<number, string>,
      )
    })

    const usedThemeGroups = computed<Array<string>>(() => {
      if (!themeGroupTree.value) return []
      return findUsedThemeGroups(usedQueries.value, themeGroupTree.value)
    })

    const themeGroupNameMap = computed(() => {
      return themeGroups.value.reduce(
        (p, c) => {
          p[c.id] = c.name
          return p
        },
        {
          '-1': 'Ungrouped themes',
        } as Record<number, string>,
      )
    })

    provide('showGroupLabels', showGroupLabels)
    provide('themeNameMap', themeNameMap)
    provide('themeGroupNameMap', themeGroupNameMap)
    provide('isOnDashboard', true)
    provide('featureFlags', featureFlags)
    provide('usedThemeGroupIds', usedThemeGroups)

    watch(currentDashboard, async (val) => {
      if (val) {
        const { group_tree } = await ThemeGroup.list(
          currentDashboard.value.project.id,
          currentDashboard.value.analysis.id,
        )

        themeGroupTree.value = group_tree
      }
    })

    // Breadcrumbs and navigation between dashboard types
    const dashboardType = computed<string>(() => {
      return (route.meta.dashboardType as string) ?? 'overview'
    })

    const currentConcepts = ref<string[]>([])
    const segment = ref<DrilldownSegment>(null)

    const breadcrumbItems = useBreadcrumbItems(
      dashboardType,
      currentConcepts,
      segment,
      themeGroupTree,
      toRef(props, 'queryId'),
    )

    return {
      dashboardType,
      currentConcepts,
      segment,
      availableQueries,
      featureFlags,
      themeToGroupNameMapById,
      themeGroupTree,
      showGroupLabels,
      usedThemeGroups,
      usedQueries,
      breadcrumbItems,
    }
  },
  data() {
    return {
      queryName: undefined as string | undefined,
      kapiche_mark_inverse,
      dashboard_chevron_right,
      loaded: false,
      projectMembers: [],
      dashboardMembers: [],
      stagedFilters: [],
      shareModalVisible: false,
      digestModalVisible: false,
      digestManagementModalVisible: false,
      dashboardError: undefined as { status: number } | undefined,
      isEditDashboardFormUpdating: false,
      editDashboardModalVisible: false,
      editDashboardFormValues: {
        name: null as string | null,
      },
      isDeleteDashboardPending: false,
      deleteDashboardModalVisible: false,
      saveAsModalVisible: false,
      saveAsFormValues: {
        name: '',
      },
      isSaveAsFormUpdating: false,
      dashboardWidgetsModalVisible: false,
      visibleDiscardAllModal: false,
      groupBy: '' as string,
      speakerField: '' as string,
      selectedQueryRows: [] as QueryRow[],
      selectedCompareQueryRows: [] as QueryRow[],
      location: QueryLocation.Dashboard,
      editSliceOneName: false,
      compareMode: false,
      overviewRef: null,
      queriesModalVisible: false,
    }
  },
  computed: {
    ...mapGetters([
      'loggedIn',
      'currentSite',
      'currentModel',
      'currentDashboard',
      'currentAnalysis',
      'defaultDateField',
      'dashboardAwaiting',
      'sortedSegmentsForFieldsLimited',
      'dashboardQueries',
      'currentUser',
      'savedQueries',
      'hasNPS',
      'hasSentiment',
      'dashboardWidgetConfig',
      'dashboardDateRange',
      'hasNumericFields',
      'hasScoreFields',
      'hasDate',
      'themeGroups',
      'expandedThemeGroups',
    ]),
    dashboardDateRangeFilters() {
      return dateRangeToFilter(
        this.dashboardDateRange?.dateField ?? '',
        this.dashboardDateRange?.type ?? DateRangeTypeEnum.ALL_TIME,
        this.dashboardDateRange?.dateFrom ?? '',
        this.dashboardDateRange?.dateTo ?? '',
      )
    },
    validQueryRows() {
      if (this.selectedQueryRows.length && !isQueryValid(this.selectedQueryRows)) return []
      return QueryUtils.convertBotanicQueriesToDashboardFilters(this.selectedQueryRows)
    },
    validQueryRowsCompare() {
      if (this.selectedCompareQueryRows.length && !isQueryValid(this.selectedCompareQueryRows)) return []
      return QueryUtils.convertBotanicQueriesToDashboardFilters(this.selectedCompareQueryRows)
    },
    outsideDateFilters() {
      const topLevelDateField = this.dashboardDateRangeFilters[0]?.field

      let otherDateFilters = this.validQueryRows.slice()
      if (this.compareMode) {
        otherDateFilters = otherDateFilters.concat(this.validQueryRowsCompare)
      }

      otherDateFilters = otherDateFilters.filter((row) => row.field === topLevelDateField)

      if (!otherDateFilters.length) {
        return []
      }

      const outsideFilters = []
      let minPrimaryDateFilter = this.dashboardDateRangeFilters.find((f) => f.op === '>=')
      let maxPrimaryDateFilter = this.dashboardDateRangeFilters.find((f) => f.op === '<=')

      let minPrimaryDate
      if (typeof minPrimaryDateFilter !== 'undefined') {
        minPrimaryDate = dayjs(minPrimaryDateFilter.value)
      } else {
        minPrimaryDate = ''
      }

      let maxPrimaryDate
      if (typeof maxPrimaryDateFilter !== 'undefined') {
        maxPrimaryDate = dayjs(maxPrimaryDateFilter.value)
      } else {
        maxPrimaryDate = dayjs()
      }

      otherDateFilters.forEach((filter) => {
        const date = dayjs(filter.value)
        if (date.isBefore(minPrimaryDate) || date.isAfter(maxPrimaryDate)) {
          outsideFilters.push(filter)
        }
      })

      return outsideFilters
    },
    filterWarnings(): FilterWarning[] {
      return this.outsideDateFilters.map((filter) => {
        return {
          field: filter.field,
          value: filter.value,
          text: 'This date falls outside the top-level Dashboard Date filter.',
        }
      })
    },
    viewedQueryNotOnDashboard() {
      if (this.dashboardType === 'query') {
        return Number.isInteger(this.queryId) && !this.usedQueries.includes(this.queryId)
      }
      if (this.dashboardType === 'theme-group') {
        return Number.isInteger(this.queryId) && !this.expandedThemeGroups.find((g) => g.id === this.queryId)
      }
      return false
    },
    routeToOverview() {
      return this.inAnalysis ?
          {
            name: 'analysis-dashboard-overview',
            projectId: this.projectId,
            analysisId: this.analysisId,
            dashboardId: this.dashboardId,
          }
        : {
            name: 'dashboard-overview',
            params: {
              dashboardId: this.dashboardId,
            },
          }
    },
    /**
       differences between current widget, queries, & filters and that in the saved dashboard
       configuration are considered to be unsaved and so save buttons should be shown
       */
    unsavedChanges() {
      return (
        this.loaded &&
        (this.unsavedSegmentFilters ||
          this.unsavedDateFilters ||
          this.unsavedWidgets ||
          this.unsavedQueries ||
          this.unsavedGroupBy ||
          this.unsavedSpeakerField ||
          this.unsavedCompareMode ||
          this.unsavedShowGroupLabels)
      )
    },
    unsavedWidgets() {
      return !isEqual(this.currentDashboard.config?.widgets, this.dashboardWidgetConfig)
    },
    unsavedSegmentFilters() {
      const saved = this.currentDashboard.config?.queryRows
      const unsaved = this.selectedQueryRows

      const compareSaved = this.currentDashboard.config?.compareQueryRows
      const compareUnsaved = this.selectedCompareQueryRows

      return !isEqual(saved, unsaved) || !isEqual(compareSaved, compareUnsaved)
    },
    unsavedDateFilters() {
      return !isEqual(this.currentDashboard.config?.dateRange, this.dashboardDateRange)
    },
    unsavedQueries() {
      return !isEqual(
        this.currentDashboard.queries.map((q) => q.name),
        this.dashboardQueries.map((q) => q.name),
      )
    },
    unsavedGroupBy() {
      return this.groupBy !== this.currentDashboard?.groupby_field
    },
    unsavedSpeakerField() {
      return this.speakerField !== this.currentDashboard?.speaker_field
    },
    unsavedCompareMode() {
      return this.compareMode !== !!this.currentDashboard?.config?.compareMode
    },
    unsavedShowGroupLabels() {
      const savedShowGroupLabel = this.currentDashboard?.config?.showGroupLabels ?? true
      return this.showGroupLabels !== savedShowGroupLabel
    },
    dateFields() {
      return (this.currentModel && this.currentModel.dateFields) || []
    },
    dateFieldNames() {
      return this.dateFields.map((f) => f.name)
    },
    selectedQueryCount() {
      const ret = this.currentDashboard?.include_other === true ? 1 : 0
      return ret + this.dashboardQueries.length
    },
    editDashboardInitialFormValues(): { name: string | null } {
      return {
        name: this.currentDashboard?.name ?? '',
      }
    },
    saveAsInitialFormValues(): { name: string | null } {
      return {
        name: '',
      }
    },
    usersWithAccess(): number {
      return this.dashboardMembers.length + this.projectMembers.length
    },
    requiredStateIsAvailable(): boolean {
      return !!this.currentDashboard?.id && !!this.currentSite && !!this.currentModel && !!this.currentAnalysis
    },
    analystUser(): boolean {
      return !this.currentUser?.viewer
    },
    groupByDiff() {
      if (!this.currentDashboard || !this.unsavedGroupBy) {
        return null
      }
      return {
        original: this.currentDashboard.groupby_field || 'None',
        current: this.groupBy || 'None',
      }
    },
    speakerFieldDiff() {
      if (!this.currentDashboard || !this.unsavedSpeakerField) {
        return null
      }
      return {
        original: this.currentDashboard.speaker_field || 'None',
        current: this.speakerField || 'None',
      }
    },
    dateRangeDiff() {
      return {
        original: this.currentDashboard.config?.dateRange,
        current: this.dashboardDateRange,
      }
    },
    themesDiff() {
      return getThemesDiff(this.dashboardQueries, this.currentDashboard?.queries)
    },
    filterDiff() {
      const diff = getFilterDiff(this.selectedQueryRows, this.currentDashboard?.config?.queryRows)
      const compareDiff = getFilterDiff(this.selectedCompareQueryRows, this.currentDashboard?.config?.compareQueryRows)

      return {
        added: diff.added,
        removed: diff.removed,
        compareAdded: compareDiff.added,
        compareRemoved: compareDiff.removed,
      }
    },
    compareModeNewValue() {
      if (this.compareMode !== !!this.currentDashboard?.config?.compareMode) {
        return this.compareMode
      }
      return undefined
    },
    compareModeView() {
      return this.compareMode && this.dashboardType === 'overview'
    },
  },
  watch: {
    dashboardId(old_id, new_id) {
      if (old_id !== new_id) this.loadDashboard(this.dashboardId)
    },
    editDashboardInitialFormValues: {
      immediate: true,
      handler(values) {
        // apply new initial values to formValues as they become available
        Object.assign(this.editDashboardFormValues, values)
      },
    },
    currentDashboard(newVal, oldVal) {
      if (newVal && newVal.id !== oldVal?.id) {
        this.groupBy = newVal.groupby_field
        this.speakerField = newVal.speaker_field
      }
    },
    saveAsModalVisible(newValue) {
      if (newValue) {
        this.saveAsFormValues.name = ''
      }
    },
  },
  beforeUnmount: function () {
    this.$store.commit(SET_DASHBOARD, null)
    // Cleanup click handler for dropdown behaviour
    document.removeEventListener('click', this.onDocumentClick)
  },
  async mounted() {
    this.whenInIFrame()
    // load rest of data
    this.loadDashboard(this.dashboardId)
  },
  methods: {
    ...mapActions({
      LOAD_DASHBOARD,
      UPDATE_DASHBOARD,
      SET_WIDGET_CONFIG,
      DISCARD_DASHBOARD_CONFIG_CHANGES,
      SAVE_DASHBOARD_DATE_RANGE,
    }),
    widgetConfigDiff(view: 'overview' | 'drilldown'): DashboardWidgetConfigDiff[] {
      return getWidgetConfigDiff(view, this.dashboardWidgetConfig, this.currentDashboard.config.widgets)
    },
    toggleDiscardAllModal(visible: boolean) {
      this.visibleDiscardAllModal = visible
    },
    discardDashboardChanges() {
      this.toggleDiscardAllModal(false)
      this.DISCARD_DASHBOARD_CONFIG_CHANGES()

      this.selectedQueryRows = this.currentDashboard.config?.queryRows ?? []
      this.selectedCompareQueryRows = this.currentDashboard.config?.compareQueryRows ?? []
      this.groupBy = this.currentDashboard.groupby_field
      this.speakerField = this.currentDashboard.speaker_field
      this.compareMode = this.currentDashboard.config?.compareMode ?? false
    },
    resetAllWidgetConfig() {
      const widgets: DashboardConfig['widgets'] = cloneDeep(this.dashboardWidgetConfig)

      ;[...widgets.overview, ...widgets.drilldown].forEach((widget) => {
        widget.visible = true
        widget.options = {}
      })

      this.$store.commit(SET_WIDGET_CONFIG, { widgets })
    },
    resetDashboard() {
      this.resetAllWidgetConfig()
      this.setDateRange(DateRangeTypeEnum.ALL_TIME, this.defaultDateField, '', '')
      this.selectAllQueries()
      this.applyQueries()

      this.selectedQueryRows = []
      this.selectedCompareQueryRows = []
    },
    setDashboardType(names: { [name: string]: string | string[] } = {}): void {
      this.currentConcepts = names['concepts']
      this.queryName = names['query']
      this.segment = names['segment']
    },
    async loadDashboard(dashboardId: number | string) {
      // In the viewer URL, the dashboard ID is hashed for security purposes.
      // The URL might look something like this:
      //
      //     http://localhost:8080/default/dashboards/YEglYOpn/overview
      //
      // The hash appears in the same position in the URL that an (DB)
      // dashboard ID normally would.

      // It has been possible to reach here trying to load a dashboard
      // from a different project (and analysis) that is currently loaded
      // in the vuex store. The check is made below to detect that
      // situation and clear the project on the store if necessary.
      const dashboardInCurrentAnalysis = this.currentAnalysis?.dashboards?.find((o) => o.id === dashboardId)
      if (!dashboardInCurrentAnalysis) {
        // The requested dashboard is not in the currently-loaded analysis. This means
        // we must invalidate our existing store state for project and everything
        // beneath it.
        this.$store.commit(CLEAR_PROJECT)
      }

      this.loaded = false
      this.dashboardError = undefined
      try {
        if (this.inAnalysis) {
          // ANALYST MODE
          await this.loadInAnalystMode(dashboardId)
        } else {
          // ELSE VIEWER MODE
          await this.loadInViewerMode(dashboardId)
        }

        this.selectedQueryRows = this.currentDashboard.config?.queryRows ?? []
        this.selectedCompareQueryRows = this.currentDashboard.config?.compareQueryRows ?? []
        this.compareMode = this.currentDashboard.config?.compareMode ?? false
        this.showGroupLabels = this.currentDashboard.config?.showGroupLabels ?? true
      } catch (error) {
        this.dashboardError = error
      } finally {
        this.loaded = true
      }
    },
    saveDashboard(): void {
      const dashboard = {
        id: this.currentDashboard.id,
        analysis_id: this.analysisId,
        name: this.currentDashboard.name,
        queries: processQueries(this.dashboardQueries),
        config: generateConfig(
          this.dashboardWidgetConfig,
          this.dashboardDateRange,
          this.selectedQueryRows,
          this.selectedCompareQueryRows,
          this.compareMode,
          this.showGroupLabels,
        ),
        groupby_field: this.groupBy,
        speaker_field: this.speakerField,
      }

      this.$store.dispatch({ type: UPDATE_DASHBOARD, dashboard, rethrowErrors: true })
      this.$analytics.track.dashboard.save(this.currentDashboard.id)
    },
    toggleFilters(filters: Array<ToggleFilterType>): void {
      const rows = applyToggledSegmentsToQueryRows(filters, this.selectedQueryRows)
      this.selectedQueryRows = rows
    },
    setDateFilters(filters: QueryRow[]): void {
      this.selectedQueryRows = filters
    },
    updateDashboardMembers(payload): void {
      // Vue.set(this, 'dashboardMembers', payload)
      this.dashboardMembers = payload
    },
    speakerFieldChange(field: string): void {
      this.speakerField = field
    },
    async loadInViewerMode(dashboardCode: string): Promise<void> {
      // After this successfully completes, the currentDashboard should
      // be the same one this dashboardCode refers to.
      await this.LOAD_DASHBOARD({ dashboardId: dashboardCode, rethrowErrors: true, loadConfig: true, isViewer: true })
      this.usedQueries = this.dashboardQueries.map((q) => q.id)
      // After this loads, currentAnalysis should be defined.
      await this.loadAnalysis()
    },
    async loadInAnalystMode(dashboardId) {
      const prevDashboard = this.currentDashboard
      let responses = await Promise.all([
        this.LOAD_DASHBOARD({
          dashboardId: dashboardId,
          analysisId: this.analysisId,
          projectId: this.projectId,
          rethrowErrors: true,
          loadConfig: true,
          isViewer: false,
        }),
        Query.listSavedQueries(this.projectId, this.analysisId),
        ProjectAPI.getDashboardMembers(dashboardId),
        ProjectAPI.getProjectMembers(this.projectId),
      ])
      this.availableQueries.value = responses[1].slice()
      this.usedQueries = this.dashboardQueries.map((q) => q.id)
      this.dashboardMembers = responses[2].slice()
      this.projectMembers = responses[3].slice()

      // If we DON'T have the analysis or the current analysis does NOT
      // match the required analysis for the dashboard. The case where
      // the previous dashboard is null, is when a user deletes a dashboard.
      // We need to refetch the list of dashboards, so we reload the analysis.
      if (this.analysisIsMissingOrStale() || prevDashboard === null) {
        await this.loadAnalysis()
      }
    },
    analysisIsMissingOrStale() {
      return (
        this.currentAnalysis === null ||
        this.currentModel === null ||
        this.currentDashboard.analysis.id !== this.currentAnalysis.id
      )
    },
    async loadAnalysis() {
      await this.$store.dispatch({
        type: LOAD_ANALYSIS,
        projectId: this.currentDashboard.project.id,
        analysisId: this.currentDashboard.analysis.id,
      })
    },
    async loadProject() {
      await this.$store.dispatch({
        type: LOAD_PROJECT,
        projectId: this.currentDashboard.project.id,
      })
    },
    showQueriesModal() {
      this.usedQueries = this.dashboardQueries.map((q) => q.id)
      this.queriesModalVisible = true
    },
    hideQueriesModal(event, resetSelections = true) {
      this.queriesModalVisible = false
      if (resetSelections) {
        this.usedQueries = this.dashboardQueries.map((q) => q.id)
      }
    },
    selectAllQueries() {
      this.usedQueries = this.availableQueries.value.map((q) => q.id)
    },
    deselectAllQueries() {
      this.usedQueries = this.usedQueries.filter((q) => q === this.queryId)
    },
    applyQueries() {
      const selectedQueries = processQueries(this.availableQueries.value.filter((q) => this.usedQueries.includes(q.id)))

      this.$store.commit(SET_DASHBOARD_QUERIES, selectedQueries)
      this.hideQueriesModal(null, false)
    },
    showExplorerMode() {
      this.$router.push({
        name: 'analysis-dashboard-overview',
        params: {
          projectId: this.currentDashboard?.project.id,
          analysisId: this.currentDashboard?.analysis.id,
          dashboardId: this.currentDashboard?.id,
        },
      })
      this.$analytics.track.dashboard.explorerMode(this.currentDashboard?.id)
    },
    showShareModal() {
      this.shareModalVisible = true
    },
    showWidgetConfigModal() {
      this.$analytics.track.dashboard.customization.showWidgetsModal(this.currentDashboard.id)
      this.dashboardWidgetsModalVisible = true
    },
    showDigestModal() {
      this.$analytics.track.dashboard.digest.modalOpened(this.currentDashboard.id)
      this.digestManagementModalVisible = true
    },
    hideShareModal() {
      this.shareModalVisible = false
    },
    // ================================================================ DASHBOARD EDITING
    showEditDashboardModal(): void {
      this.editDashboardModalVisible = true
    },
    resetEditDashboardForm(): void {
      Object.assign(this.editDashboardFormValues, this.editDashboardInitialFormValues)
    },
    closeEditDashboardModal(): void {
      this.resetEditDashboardForm()
      this.editDashboardModalVisible = false
    },
    async saveEditDashboard(): Promise<void> {
      const { name } = this.editDashboardFormValues
      try {
        this.isEditDashboardFormUpdating = true
        await this.UPDATE_DASHBOARD({
          dashboard: {
            id: this.currentDashboard.id,
            analysis_id: this.currentAnalysis.id,
            name,
          },
          rethrowErrors: true,
        })
        // update dashboards list
        await this.loadAnalysis()
        // close modal
        this.closeEditDashboardModal()
      } catch (resp) {
        if (resp.status === 400) {
          const errors = await resp.json()
          // pass errors to form context to highlight each input's errors
          this.$refs.editDashboardObserver.setErrors(errors)
        }
      } finally {
        this.isEditDashboardFormUpdating = false
      }
    },
    // ================================================================ DASHBOARD DELETING
    showDeleteDashboardModal(): void {
      this.deleteDashboardModalVisible = true
    },
    closeDeleteDashboardModal(): void {
      this.deleteDashboardModalVisible = false
    },
    async deleteDashboard(): Promise<void> {
      try {
        this.isDeleteDashboardPending = true
        const newId = this.currentAnalysis.dashboards.find(({ id }) => id !== this.currentDashboard.id)?.id
        await ProjectAPI.deleteDashboard(this.currentDashboard.id)
        this.$store.commit(SET_DASHBOARD, null)
        // close modal before changing Dashboards
        this.deleteDashboardModalVisible = false
        // load first Dashboard in current analysis
        this.$router.push({
          name: 'analysis-dashboard-overview',
          params: {
            ...this.$route.params,
            dashboardId: newId,
          },
        })
      } catch (errors) {
        // close modal (handle error generally)
        this.closeDeleteDashboardModal()
        this.$store.dispatch({ type: SET_ERRORS, errors })
      } finally {
        this.isDeleteDashboardPending = false
      }
    },
    showSaveAsModal(): void {
      this.saveAsModalVisible = true
    },
    resetSaveAsDashboardForm(): void {
      Object.assign(this.saveAsFormValues, this.saveAsInitialFormValues)
    },
    updateFormValue(value) {
      this.saveAsFormValues.name = value
      this.$refs.saveAsObserver.validate()
    },
    closeSaveAsModal(): void {
      this.saveAsFormValues.name = ''
      this.saveAsModalVisible = false
      if (this.$refs.saveAsObserver) {
        this.$refs.saveAsObserver.resetForm()
      }
    },
    async saveNewDashboard(): Promise<void> {
      const { name } = this.saveAsFormValues
      try {
        this.isSaveAsFormUpdating = true

        const res = await ProjectAPI.createDashboard({
          name,
          analysis: this.analysisId,
          queries: this.dashboardQueries.map(({ id }) => ({ id })),
          slice1_name: this.currentDashboard.slice1_name,
          slice2_name: this.currentDashboard.slice2_name,
          config: {
            dateRange: this.dashboardDateRange,
            widgets: this.dashboardWidgetConfig,
            queryRows: this.selectedQueryRows,
            compareQueryRows: this.selectedCompareQueryRows,
            compareMode: this.compareMode,
          },
        })

        // update dashboards list
        await this.loadAnalysis()

        this.closeSaveAsModal()

        const oldDashboardId = this.currentDashboard.id
        const newDashboardId = res.body.id
        this.$router.push({
          name: 'analysis-dashboard-overview',
          params: {
            site: this.$route.params.site,
            projectId: this.$route.params.projectId,
            analysisId: this.$route.params.analysisId,
            dashboardId: newDashboardId,
          },
        })

        this.$analytics.track.dashboard.saveAs(oldDashboardId, newDashboardId)
      } catch (error) {
        if (error.status === 400 && error.body && error.body.name) {
          this.$refs.saveAsObserver.setErrors({ 'Dashboard title': error.body.name[0] })
        } else if (error.bodyText) {
          const parsedBody = JSON.parse(error.bodyText)
          if (parsedBody.name && parsedBody.name.length > 0) {
            this.$refs.saveAsObserver.setErrors({ 'Dashboard title': parsedBody.name[0] })
          }
        }
      } finally {
        this.isSaveAsFormUpdating = false
      }
    },
    setDateRange(type: string, dateField: string, to: string, from: string) {
      this.SAVE_DASHBOARD_DATE_RANGE({
        dateRange: {
          type: type,
          dateField,
          dateTo: to,
          dateFrom: from,
        },
      })
    },
    onWidgetConfigChange(config: DashboardConfig['widgets']) {
      this.$analytics.track.dashboard.customization.widgetConfigApplied(this.currentDashboard.id, config)
      this.$store.commit(SET_WIDGET_CONFIG, { widgets: config })
      this.dashboardWidgetsModalVisible = false
      this.triggerLayout()
    },
    onLinkCopy() {
      this.$analytics.track.dashboard.linkCopied(this.currentDashboard.id)
    },
    // Hide the dropdown menu
    onDocumentClick() {
      this.stagedFilters = []
      let el = this.$el.querySelector('.right.menu .dropdown .menu')
      if (el) {
        el.classList.remove('visible')
      }
    },
    hasUnstructuredData(query) {
      // FIXME: when https://github.com/Kapiche/botanic/pull/3661 merges
      return (
        QueryUtils.botanicToQueryRows(query).find((i) => ['text', 'query', 'attribute'].includes(i.type)) !== undefined
      )
    },
    whenInIFrame() {
      if (top !== self) {
        // means we are in an iframe
        const wrapperElement = this.$refs.dashboardWrapper

        new ResizeSensor(wrapperElement, function () {
          window.parent.postMessage(
            JSON.stringify({
              src: window.location.toString(),
              context: 'iframe.resize',
              height: wrapperElement.scrollHeight,
            }),
            '*',
          )
        })
      }
    },
    scrollToTop() {
      ;(this.$refs.scrollableContent as Element)?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    },
    generateSegmentFilterCountLabel: Util.generateSegmentFilterCountLabel,
    triggerLayout() {
      /*
        Each widget listens to resize events to determine when they should update the dimensions of their
        their visualisations.  This normally happens when the browser window is resized.

        However there are other circumstances (such as when widget visibility changes) that can affect
        the number of columns and hence the width of each column which will require the widgets to adjust
        their visualisation parameters.

        This method lets us dispatch that event and trigger the widget resizing behaviour when necessary.
         */
      window.dispatchEvent(new Event('resize'))
    },
    updateGroupby(value: string | null) {
      if (value !== null) {
        this.$analytics.track.dashboard.groupByAggregation(this.currentDashboard.id, value)
      }
      this.groupBy = value
    },
    syncFilterBarCollapse(collapsed: boolean) {
      if (this.$refs.filterBar1) this.$refs.filterBar1.collapsed = collapsed
      if (this.$refs.filterBar2) this.$refs.filterBar2.collapsed = collapsed
    },
    exportCsv() {
      this.overviewRef?.exportQuery()
    },
    exportPpt() {
      this.overviewRef?.exportPpt()
    },
    async renameSlice(field: 'slice1_name' | 'slice2_name', value: string) {
      await this.UPDATE_DASHBOARD({
        dashboard: {
          id: this.currentDashboard.id,
          analysis_id: this.currentAnalysis.id,
          [field]: value,
        },
        rethrowErrors: true,
      })
    },
  },
})
</script>

<style lang="sass" scoped>
/* explicitly importing semantic here, so we know it's used here */
@import 'semantic/dist/semantic.css'
@import 'assets/kapiche.sass'
@import 'assets/masked.sass'

.tooltip.changes
  background-color: $text-black
  padding: 25px
  overflow-y: auto
  max-height: 400px
  header
    margin-bottom: 15px
    font-size: 14px

.bf-button.clear
  padding-left: 0
  padding-right: 0
  .icon-wrapper
    margin-right: 4px

#dashboard-wrapper
  background-color: $grey-light-background
  height: 100vh  // Allows us to define child height as a %
  display: flex
  flex-direction: column // Allows us to use flexbox for section layout heights

  #dashboardContent
    display: flex
    flex-direction: column
    flex: 1 1 0 // Override auto height with flexible remainder height for main content
    overflow-y: hidden

  .item.borderless.logo
    width: 80px
    .ui.image.small
      height: 32px

  .top.menu
    height: 5rem

    .right.menu
      flex: 0 0 auto
      min-width: 0
      .item
        padding-right: 1em
        padding-left: 1em
      a.item
        color: #95a6ac

    div.left.menu
      flex: 1 1 0
      min-width: 0
    .breadcrumbs
      .breadcrumb
        flex-direction: column
        align-items: flex-start
        justify-content: center
        padding-left: 16
        padding-right: 16px
        min-width: 0
        flex: 0 1 auto
      .item.borderless.chevron
        padding-left: 0
        padding-right: 0
        .chevron-img
          height: 15px
          width: 15px
          opacity: 0.3
      .label-item
        font-size: 11px
        font-weight: bold
        line-height: 13px
        color: rgba(149, 166, 172, 0.7)
        opacity: 0.7
        text-transform: uppercase
      .link
        font-size: 16px
        color: rgb(255, 255, 255)
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        width: 100%

  #dashboardMenu
    margin: 0
    background-color: #012a38
    z-index: 10  // This is to get above the semantic ui loader, which dashboard widgets use.

    .analyst-view-message
      margin: auto

      .analyst-view-message-text
        color: $white
        text-align: center
        margin-right: 10px
        font-weight: bold

div.error-box
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 50px
  font-family: $standard-font
  p
    font-size: 16px
  .text-box
    font-size: 16px
    margin-bottom: 40px

.query-modal
  .muted
    color: rgba(56, 56, 56, 0.3)
  > p
    text-align: center
    font-size: 1.15rem
  .segment
    height: 25%
    max-height: 50vh
    overflow-y: auto
    border-right: none
    border-left: none
    box-shadow: none
    margin-top: 2rem
    .ui.form
      margin-top: 0
      label
        cursor: pointer
        font-size: 1.3rem
  .select-actions
    display: flex
    color: $text-grey
    font-weight: bold
    text-transform: uppercase
    font-size: 14px
    .select-action-item
      cursor: pointer
      margin-right: 20px

div.dialog
  width: 540px
  max-width: 90vw

  form .input-section
    width: 100%

.edit-dashboard, .delete-dashboard, .save-as-dashboard
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center
  .form-body
    width: 100%
    margin-bottom: 10px
  .bf-text-input
    margin: 5px 0 10px
  .buttons
    display: flex
    gap: 15px 30px
    .bf-button
      margin: 0

  .info-msg
    text-align: center
    margin-bottom: 1.5em
    word-break: break-word
    p
      margin-bottom: 0.5em
  label
    font-weight: bold

.query-builder
  margin: 0 30px 15px

.filter-bars
  display: flex
  padding: 0 30px
  position: sticky
  top: 0px
  z-index: 5
  background: $grey-light-background

  > div
    flex: 1
    margin: 0
    &:not(:last-of-type)
      margin-right: 32px

.save-button
  height: 30px
  line-height: 30px

.group-tag
  font-size: 18px
</style>
