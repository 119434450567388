<template>
  <widget-frame
    ref="root"
    :zoomed="isZoomed"
    :masked="masked"
    :is-loading="isLoading"
    :dev-mode="devMode"
    :has-errored="sliceOneData && sliceOneData.error != null"
    :banner="banner"
    class="timeline"
    @resize="setChartDimensions"
  >
    <template #icon>
      <img class="header-icon" :src="icon" alt="Dashboard themes icon" />
    </template>
    <template #header> Timeline </template>
    <template #actions>
      <download-export-button
        :name="exportName + '-Compare Timeline'"
        :is-loading="isLoading"
        :get-el="getTrendEl"
        :get-csv-data="getCsvData"
        :get-svg-export-config="getExportConfig"
        :make-ppt-slide="makePptSlide"
        short-name="Compare Timeline"
      ></download-export-button>
      <router-link v-if="!isZoomed && zoomToRoute" class="widget-action expand" :to="zoomToRoute">
        <i class="kapiche-icon-fullscreen"></i>
      </router-link>
      <a :href="CONST.widget_help_links.timeline" class="widget-action help" target="_blank">
        <i class="kapiche-icon-info"></i>
      </a>
    </template>
    <!--======================== DEV PANEL -->
    <template #devPanel>
      <div>
        Start: {{ new Date(sliceOneData.startTime || 0) }}<br />
        Done: {{ new Date(sliceOneData.doneTime || 0) }}<br />
        Elapsed: {{ ((sliceOneData.doneTime || 0) - (sliceOneData.startTime || 0)) / 1000 }} seconds<br />
        Status: {{ sliceOneData.status }}<br />
        Error: {{ sliceOneData.error }}
        <hr />
        <h2>this.props</h2>
        <code style="white-space: pre">
          {{ JSON.stringify($props, null, 2) }}
        </code>
      </div>
    </template>
    <!--======================== ERROR PANEL -->
    <template #error-panel>
      <div v-if="sliceOneData.error" class="error-panel">
        <h3>
          <img class="errorIcon" :src="errorIcon" alt="widget error icon" />
          Opps, something went wrong while loading this widget.
        </h3>
        <div class="action">
          Try
          <button @click.stop="reload">reloading this widget</button>
          or
          <button @click.stop="refresh">reloading the page</button>
        </div>
        <div class="action">
          <button @click.stop="contact">Contact support</button>
          if the problem persists.
        </div>
        <div v-if="userError" class="message">
          {{ userError }}
        </div>
      </div>
    </template>

    <template #menu>
      <widget-menu :menus="menus" :vertical="isZoomed" :bound="root && root.$el" @onSelect="setMenuSelection" />
    </template>
    <!--======================== CONTENT -->
    <template v-if="!isLoading && sortedSeries.length > 0" #content>
      <div v-if="!isLoading" class="legend">
        <div>
          <span></span>
          <span>{{ sliceOneName }}</span>
        </div>
        <div>
          <span></span>
          <span>{{ sliceTwoName }}</span>
        </div>
      </div>
      <div class="sort-row">
        <sort-controls
          :sort-options="sortOptions"
          :selected-sort-option="seriesSort"
          :visible-count="seriesLimit"
          :visible-options="[6, 9, 12, 15]"
          @update-visible-count="seriesLimit = $event"
          @update-sort-method="seriesSort = $event"
        />
        <div class="slice-label">({{ sliceOneName }})</div>
      </div>
      <div class="timeline-container" :class="{ 'tool-tip-padded': isZoomed }">
        <div v-for="(row, y) in chunkArray(sortedSeries, 3)" :key="row[0][0].id">
          <div v-for="(series, x) in row" :key="series[0].id">
            <div class="category-label">
              <span v-truncate="getSeriesTag(series[0]) ? 50 : 25">
                {{ getSeriesName(series[0]).replace(` (${sliceOneName})`, '') }}
              </span>
              <div v-if="getSeriesTag(series[0])" class="group-tag">
                [<span v-truncate="25">{{ getSeriesTag(series[0]) }}</span
                >]
              </div>
            </div>
            <timeline
              :timeline-id="'timeline-trend'"
              :all-series="series"
              :resolution="resolution.toLowerCase()"
              :y-value-number-format="selectedModeOption.numberType"
              :y-label="selectedModeOption.yAxisLabel"
              :y-range="yRange"
              :records="records"
              :y-axis-left-ticks="3"
              :chart-height="chartHeight"
              :visible-y-axis-labels="y === 0 && x === 0 ? [0, -1] : []"
              :visible-x-axis-labels="y === 0 && x === 0 ? [0, -1] : []"
              :gutter-left="x === 0 ? 50 : 40"
              :gutter-right="35"
              :gutter-bottom="15"
              :series-labels="seriesLabels"
              @series-visibility-changed="
                () => {
                  calculateYRange()
                  updateConfig()
                }
              "
            />
          </div>
        </div>
      </div>
    </template>
    <template v-else #content>
      <widget-message-panel>
        <template #title>
          <span>No Data</span>
        </template>
        <template #message>
          <span>There is not sufficient data to display this widget.</span>
        </template>
      </widget-message-panel>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import dayjs from 'dayjs'
import { computed, ComputedRef, defineComponent, inject, onMounted, PropType, ref, watch } from 'vue'
import PptxGenJS from 'pptxgenjs'
import { useStore } from 'vuex'

import WidgetMenu from 'components/DataWidgets/WidgetMenu/WidgetMenu.vue'
import WidgetFrame from 'components/widgets/WidgetFrame/WidgetFrame.vue'
import icon from 'assets/img/dashboards/dash-timeline.svg'
import errorIcon from 'assets/icons/alert-bubble.svg'
import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
import Timeline from 'components/project/analysis/results/widgets/Timeline.vue'
import DrawUtils from 'src/utils/draw'
import ProjectAPI from 'src/api/project'
import { adjustColor, getAggregationOffset, getDataMax, getDataMin, makeTimelineSlide } from '../DataWidgetUtils'
import { FetchState } from 'src/store/modules/data/state'
import { WidgetConfig } from 'src/types/DashboardTypes'
import { SavedQuery } from 'src/types/Query.types'
import { PivotData, Resolution, TrendLine } from 'src/types/widgets.types'
import { SchemaColumn } from 'src/types/SchemaTypes'
import { Analytics } from 'src/analytics'
import { DisplayModeOption, Menu, MenuItem } from './Timeline.vue'
import { ExpandedGroup } from 'src/pages/dashboard/Dashboard.utils'
import WidgetMessagePanel from 'components/widgets/WidgetMessagePanel/WidgetMessagePanel.vue'
import { getScoreColumnRegroupMap, generateScoreRequirements, generateScoreFilters } from './TimelineScoreUtils'
import { ScoreColumn, schemaColToScoreCol } from 'src/utils/score'
import SortControls from '../SortControls.vue'
import { SortOption, sortOptions, sortSeries } from './Timeline.utils'

type FieldFrequency = FetchState<PivotData>

const overallNPSColor = '#068dcc'

const impact_sentiment_extra = {
  numberType: 'integer',
  yCapValue: 200,
  yMultipleOf: 10,
}

const metric_sentiment_extra = {
  numberType: 'integer',
  yCapValue: 100,
  yMultipleOf: 10,
}

const RESOLUTIONS = ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly']
const defaultSort: SortOption = 'Highest relative change'

const TimelineWidget = defineComponent({
  components: {
    WidgetFrame,
    DownloadExportButton,
    Timeline,
    WidgetMenu,
    WidgetMessagePanel,
    SortControls,
  },
  props: {
    sliceOneData: { type: Object as PropType<FetchState<PivotData>>, required: false, default: null },
    sliceTwoData: { type: Object as PropType<FetchState<PivotData>>, required: false, default: null },
    sliceOneName: { type: String, required: true },
    sliceTwoName: { type: String, required: true },
    sliceOneFilters: { type: Array, required: false, default: () => [] },
    sliceTwoFilters: { type: Array, required: false, default: () => [] },
    fieldFrequencySliceOne: { type: Object as PropType<FieldFrequency | null>, required: false, default: null },
    fieldFrequencySliceTwo: { type: Object as PropType<FieldFrequency | null>, required: false, default: null },
    exportName: { type: String, required: false, default: '' },
    devMode: { type: Boolean, required: false, default: false },
    baseQuery: { type: Object, default: () => ({}) },
    queries: { type: Array as PropType<SavedQuery[]>, required: false, default: () => [] },
    segmentFields: { type: Array as PropType<SchemaColumn[]>, required: false, default: () => [] },
    hasNps: { type: Boolean, required: false, default: false },
    hasSentiment: { type: Boolean, required: false, default: false },
    hasNumericFields: { type: Boolean, default: false, required: false },
    banner: { type: Object, default: () => null, required: false },
    dateFields: { type: Array as PropType<SchemaColumn[]>, required: true },
    defaultDateField: { type: String, required: true },
    weekStart: { type: String, required: false, default: null },
    sortedSegmentsForFieldsLimited: { type: Object, required: true },
    group: { type: String, required: false, default: 'overall__' },
    schema: { type: Array as PropType<SchemaColumn[]>, required: true },
    isZoomed: { type: Boolean, required: false, default: false },
    zoomToRoute: { type: Object, required: false, default: null },
    masked: { type: Boolean, required: false, default: false },
    config: { type: Object as PropType<WidgetConfig<'compare-timeline'> | null>, required: false, default: null },
    dayFirstDates: { type: Boolean, required: false, default: false },
    themeGroups: { type: Array as PropType<ExpandedGroup[]>, required: false, default: () => [] },
  },
  setup(props, { emit }) {
    const analytics = inject<Analytics>('analytics')
    const store = useStore()
    const themeToGroupNameMap = computed<Record<number, string>>(() => store.getters['themeToGroupNameMapById'] ?? {})
    const themeNameMap = inject<ComputedRef<Record<number, string>>>(
      'themeNameMap',
      computed(() => ({})),
    )
    const showGroupLabels = inject<boolean>('showGroupLabels', true)

    const root = ref<InstanceType<typeof WidgetFrame> | null>(null)

    const selectedDisplayMode = ref<[string, string]>(['Frequency', 'Frequency (%)'])
    const selectedDataDisplayMode = ref<[string, string]>(['Other', 'Themes'])
    const selectedDatefield = ref<string>(props.defaultDateField)
    const resolution = ref<Resolution>('Monthly')
    const sliceOneSeries = ref<TrendLine[]>([])
    const sliceTwoSeries = ref<TrendLine[]>([])
    const yRange = ref<[number, number]>([0, 1])
    const records = ref<Record<string, any>>({})
    const chartHeight = ref('160px')
    const seriesLimit = ref(12)
    const seriesSort = ref<SortOption>(defaultSort)

    const seriesLabels = computed<Record<string, string>>(() => {
      // Theme Groups are prefixed with 'group_' to avoid conflicts with Themes
      const labels = props.themeGroups.flatMap((g) => {
        const sliceOneName = `${g.name} (${props.sliceOneName})`
        const sliceTwoName = `${g.name} (${props.sliceTwoName})`
        return [
          [sliceOneName, sliceOneName.replace(/^group_/, '')],
          [sliceTwoName, sliceTwoName.replace(/^group_/, '')],
        ]
      })
      return Object.fromEntries(labels)
    })

    const getSeriesName = (series: TrendLine): string => {
      return seriesLabels.value[series.name] ?? series.name
    }

    const getSeriesTag = (series: TrendLine): string => {
      if (series.query_id == null || !showGroupLabels.value) return ''
      return themeToGroupNameMap.value[series.query_id] ?? ''
    }

    const numericalFields = computed((): string[] => {
      return props.segmentFields.reduce(
        (list, field) =>
          field.type === ProjectAPI.COLUMN_LABELED_TYPES.get('NUMBER') ? list.concat(field.name) : list,
        [] as string[],
      )
    })

    const dataOptions = computed((): MenuItem[][] => {
      return [
        [
          {
            title: 'Field',
            type: 'menu',
            options: Object.keys(props.sortedSegmentsForFieldsLimited),
          },
        ],
        [
          {
            title: 'Other',
            type: 'menu',
            options: ['Themes', 'Theme Groups'],
          },
        ],
      ]
    })

    const validOptions = computed((): Required<WidgetConfig<'compare-timeline'>['options']> => {
      let dataDisplayMode = props.config?.options?.dataDisplayMode ?? [null, null]
      let displayMode = props.config?.options?.displayMode ?? [null, null]
      let dateField = props.config?.options?.dateField ?? ''
      let resolution: Resolution = props.config?.options?.resolution ?? 'Monthly'
      let seriesLimit = props.config?.options?.seriesLimit ?? 12
      let seriesSort = props.config?.options?.seriesSort ?? defaultSort

      if (
        !dataDisplayMode[0] ||
        (dataDisplayMode[0] === 'Field' &&
          !Object.keys(props.sortedSegmentsForFieldsLimited).includes(dataDisplayMode[1]))
      ) {
        if (props.queries.length > 0) {
          dataDisplayMode = ['Other', 'Themes']
        } else if (props.themeGroups.length > 0) {
          dataDisplayMode = ['Other', 'Theme Groups']
        } else {
          dataDisplayMode = [dataOptions.value[0][0].title, dataOptions.value[0][0].options[0] as string]
        }
      }

      // We don't store the score column display mode as is, but we strip the aggregation from the title,
      // In order to restore the title, we find the score column and set it as expected.
      let scoreColNames = scoreColumns.value.map((col) => col.name)

      // set selected display
      // noinspection FallThroughInSwitchStatementJS
      switch (displayMode[0]) {
        case 'Frequency': {
          if (['Frequency (%)', 'Frequency (#)'].includes(displayMode[1])) {
            break
          }
          displayMode = ['Frequency', 'Frequency (%)']
          break
        }
        case 'Numerical Field':
        case 'Score': {
          // We check which and update the displayMode[0] to be Numerical Field/ Score accordingly.
          // We need to do this check everytime because numerical and score fields are interchangeable.
          if (numericalFields.value.includes(displayMode[1])) {
            displayMode = ['Numerical Field', displayMode[1]]
          } else if (scoreColumns.value && scoreColNames.includes(displayMode[1])) {
            displayMode = ['Score', displayMode[1]]
          }
          break
        }
        case 'Metric':
        case 'Impact On': {
          if (displayMode[1] === 'NPS' && props.hasNps) {
            break
          }
          if (
            ['Positive Sentiment', 'Negative Sentiment', 'Mixed Sentiment', 'Neutral Sentiment'].includes(
              displayMode[1],
            ) &&
            props.hasSentiment
          ) {
            break
          }
          if (numericalFields.value.includes(displayMode[1])) {
            break
          }
          if (scoreColumns.value && scoreColNames.includes(displayMode[1])) {
            break
          }
          displayMode = ['Frequency', 'Frequency (%)']
          break
        }
        default: {
          displayMode = ['Frequency', 'Frequency (%)']
        }
      }

      // set selected date
      if (!props.dateFields.map((d) => d.name).includes(dateField)) {
        dateField = props.defaultDateField
      }

      // set selected resolution
      if (!RESOLUTIONS.includes(resolution)) {
        resolution = 'Monthly'
      }

      return {
        dataDisplayMode,
        displayMode,
        dateField,
        resolution,
        seriesLimit,
        seriesSort,
      }
    })

    const fetchData = (force = false) => {
      // TODO: a refactor could unify this code and the fetchData body in ThemesWidget.vue
      let blocks: any[] = [
        {
          aggfuncs: [
            {
              new_column: 'frequency_cov',
              src_column: 'document_id',
              aggfunc: 'count',
            },
          ],
          metric_calculator: 'coverage',
        },
      ]

      // get NPS
      if (props.hasNps) {
        blocks.push({
          aggfuncs: [
            {
              new_column: 'frequency',
              src_column: 'document_id',
              aggfunc: 'count',
            },
          ],
          pivot_field: 'NPS Category',
          metric_calculator: 'nps',
        })
      }

      // get sentiment
      if (props.hasSentiment) {
        blocks.push({
          aggfuncs: [
            {
              new_column: 'frequency',
              src_column: 'document_id',
              aggfunc: 'count',
            },
          ],
          pivot_field: 'sentiment__',
          metric_calculator: 'sentiment',
        })
      }

      const field = selectedDisplayMode.value[1]
      const isNumericalField = numericalFields.value.includes(field)

      if (isNumericalField) {
        blocks.push({
          aggfuncs: [
            {
              new_column: `${field}|count`,
              src_column: `${field}`,
              aggfunc: 'count',
            },
            {
              new_column: `${field}|mean__`,
              src_column: `${field}`,
              aggfunc: 'mean',
            },
          ],
          metric_calculator: 'mean_impact',
        })
      }

      // Add score blocks to the requirements if present.
      const scoreBlocks = generateScoreRequirements(scoreColumns.value, selectedDisplayMode.value[1])
      const filters = generateScoreFilters(scoreColumns.value, selectedDisplayMode.value[1])
      if (Object.keys(scoreBlocks).length > 0) blocks.push(scoreBlocks)
      // TODO: this may or may not be present, depending on whether segments or queries is selected.
      let extra: Record<string, unknown> = {}
      if (selectedDataDisplayMode.value.join(',') === 'Other,Themes') {
        extra['queries'] = props.queries.map((q) => ({
          name: `q_${q.id}`,
          value: q.query_value,
        }))
      } else if (selectedDataDisplayMode.value.join(',') === 'Other,Theme Groups') {
        extra['queries'] = props.themeGroups.map((g) => ({
          name: g.name,
          value: g.query_value,
        }))
      } else if (selectedDataDisplayMode.value[0] === 'Field') {
        extra['agg_fields'] = selectedDataDisplayMode.value[1] ? [selectedDataDisplayMode.value[1]] : []
      }

      const requirements = {
        blocks,
        ...extra,
        date_fieldname: selectedDatefield.value,
        date_aggregation_offset: getAggregationOffset(resolution.value),
        week_start: props.weekStart,
      }

      let field_frequency_requirements: Record<string, unknown> = {
        blocks: [
          {
            aggfuncs: [
              {
                new_column: 'frequency',
                src_column: 'document_id',
                aggfunc: 'count',
              },
            ],
            pivot_field: selectedDataDisplayMode.value[1],
          },
        ],
        date_fieldname: selectedDatefield.value,
        date_aggregation_offset: getAggregationOffset(resolution.value),
      }

      // Fetch frequency totals if we're looking at a field
      if (selectedDataDisplayMode.value[0] === 'Field') {
        emit('requires', 'field-frequency-slice-one', field_frequency_requirements, force, [
          ...props.sliceOneFilters,
          ...filters,
        ])
        emit('requires', 'field-frequency-slice-two', field_frequency_requirements, force, [
          ...props.sliceTwoFilters,
          ...filters,
        ])
      }

      // request for segmentation chart overall data
      emit('requires', 'compare-timeline-slice-one', requirements, force, [...props.sliceOneFilters, ...filters])

      emit('requires', 'compare-timeline-slice-two', requirements, force, [...props.sliceTwoFilters, ...filters])
    }

    const refresh = () => {
      window.location.reload()
    }

    const contact = () => {
      try {
        window.Intercom('show')
      } catch {
        console.warn('intercom show failed')
      }
    }

    const reload = () => {
      fetchData(true)
    }

    const setOptionsFromConfig = () => {
      selectedDataDisplayMode.value = validOptions.value.dataDisplayMode
      selectedDisplayMode.value = validOptions.value.displayMode
      selectedDatefield.value = validOptions.value.dateField
      resolution.value = validOptions.value.resolution
      seriesLimit.value = validOptions.value.seriesLimit
      seriesSort.value = validOptions.value.seriesSort
    }

    /**
     * Quick accessor to get the selected display mode option without having to call into the dict
     * @return option - A dict of all the relevant options for a given mode (label, percentSign, accessor)
     */
    const selectedModeOption = computed((): DisplayModeOption => {
      if (selectedDisplayMode.value[0] === 'Numerical Field') {
        return {
          label: selectedDisplayMode.value[1],
          numberType: 'signAwareRoundedFloat',
          yAxisLabel: `${selectedDisplayMode.value[1]} (avg)`,
          yCapValue: 1e6,
          yMultipleOf: 0.1,
        }
      }

      if (
        selectedDisplayMode.value[0] === 'Impact On' &&
        numericalFields.value.includes(selectedDisplayMode.value[1])
      ) {
        return {
          label: `${selectedDisplayMode.value[1]} Impact`,
          numberType: 'signAwareRoundedFloat',
          yAxisLabel: `Impact on ${selectedDisplayMode.value[1]}`,
          yCapValue: 1e6,
          yMultipleOf: 0.1,
        }
      }

      // Construct an array for the score displayMode options.
      const scoreNames = scoreColumns.value.map((col) => col.name)

      if (selectedDisplayMode.value[0] === 'Score' && scoreNames.includes(selectedDisplayMode.value[1])) {
        const selectedScoreColumn = scoreColumns.value.find((col) => col.name === selectedDisplayMode.value[1])
        const isBox = ['top box', 'bot box'].includes(selectedScoreColumn?.aggregation.type ?? 'average')
        return {
          label: `${selectedScoreColumn?.name} (${selectedScoreColumn?.aggregation.title})`,
          numberType: isBox ? 'percentage' : 'signAwareRoundedFloat',
          yAxisLabel: `${selectedScoreColumn?.name} (${selectedScoreColumn?.aggregation.title})`,
          yCapValue: isBox ? 100 : 1e6,
          yMultipleOf: isBox ? 1 : 0.1,
        }
      }

      if (selectedDisplayMode.value[0] === 'Impact On' && scoreNames.includes(selectedDisplayMode.value[1])) {
        const selectedScoreColumn = scoreColumns.value.find((col) => col.name === selectedDisplayMode.value[1])
        return {
          label: `${selectedScoreColumn?.name} (${selectedScoreColumn?.aggregation.title}) Impact`,
          numberType: 'signAwareRoundedFloat',
          yAxisLabel: `Impact on ${selectedDisplayMode.value[1]} (${selectedScoreColumn?.aggregation.title})`,
          yCapValue: 1e6,
          yMultipleOf: 0.1,
        }
      }

      const displayModeOptions = {
        'Frequency Frequency (%)': {
          label: 'Frequency (%)',
          numberType: 'percentage',
          yAxisLabel: '% of Records',
          yCapValue: 1,
          yMultipleOf: 0.1,
          allowEnhance: true,
        },
        'Frequency Frequency (#)': {
          label: 'Frequency (#)',
          numberType: 'integer',
          yAxisLabel: 'Records',
          yCapValue: 1e6,
          yMultipleOf: 5,
        },
        'Impact On Positive Sentiment': {
          label: 'Positive Sentiment Impact',
          queryLabel: 'positive',
          yAxisLabel: 'Impact on pos. sentiment (%)',
          ...impact_sentiment_extra,
        },
        'Impact On Negative Sentiment': {
          label: 'Negative Sentiment Impact',
          queryLabel: 'negative',
          yAxisLabel: 'Impact on neg. sentiment (%)',
          ...impact_sentiment_extra,
        },
        'Impact On Mixed Sentiment': {
          label: 'Mixed Sentiment Impact',
          queryLabel: 'mixed',
          yAxisLabel: 'Impact on mixed sentiment (%)',
          ...impact_sentiment_extra,
        },
        'Impact On NPS': {
          label: 'NPS Impact',
          numberType: 'signAwareInteger',
          yAxisLabel: 'Impact on NPS',
          yCapValue: 200,
          yMultipleOf: 5,
        },
        'Metric Positive Sentiment': {
          label: 'Positive Sentiment',
          queryLabel: 'positive',
          yAxisLabel: 'Positive sentiment (%)',
          ...metric_sentiment_extra,
        },
        'Metric Negative Sentiment': {
          label: 'Negative Sentiment',
          queryLabel: 'negative',
          yAxisLabel: 'Negative sentiment (%)',
          ...metric_sentiment_extra,
        },
        'Metric Mixed Sentiment': {
          label: 'Mixed Sentiment',
          queryLabel: 'mixed',
          yAxisLabel: 'Mixed sentiment (%)',
          ...metric_sentiment_extra,
        },
        'Metric NPS': {
          label: 'NPS',
          numberType: 'signAwareInteger',
          yAxisLabel: 'NPS',
          yCapValue: 200,
          yMultipleOf: 5,
        },
      } as Record<string, DisplayModeOption>

      return displayModeOptions[selectedDisplayMode.value.join(' ')]
    })

    const optionsForDataMenu = computed((): MenuItem[][] => {
      return dataOptions.value.map((t) =>
        t.map((o) => ({
          title: o.title,
          type: 'menu',
          options: o.options,
          showSelected: true,
          selected: selectedDataDisplayMode.value[0] === o.title ? selectedDataDisplayMode.value[1] : [],
        })),
      )
    })

    const scoreColumns = computed((): ScoreColumn[] => {
      return props.schema.filter((col) => col.type === 8).map((col) => schemaColToScoreCol(col))
    })

    const displayOptions = computed((): MenuItem[][] => {
      const standardOptions = ['NPS', 'Positive Sentiment', 'Negative Sentiment', 'Mixed Sentiment']
      const scoreColNames = scoreColumns.value.map((col) => `${col.name} (${col.aggregation.title})`)

      const options: Array<MenuItem[] | boolean> = [
        [
          { title: 'Frequency', type: 'menu', options: ['Frequency (%)', 'Frequency (#)'] },
          { title: 'Date Field', type: 'radio', options: [] },
        ],
        props.hasNumericFields && [
          {
            title: 'Numerical Field',
            type: 'menu',
            options: numericalFields.value,
          },
        ],
        scoreColumns.value.length > 0 && [
          {
            title: 'Score',
            type: 'menu',
            options: scoreColNames,
          },
        ],
        [
          {
            title: 'Metric',
            type: 'menu',
            options: standardOptions,
          },
        ],
        [
          {
            title: 'Impact On',
            type: 'menu',
            options: standardOptions.concat(numericalFields.value).concat(scoreColNames),
          },
        ],
      ]

      return options.filter((i): i is MenuItem[] => !!i)
    })

    const selectedForDisplayOptions = (option: MenuItem) => {
      switch (option.title) {
        case 'Date Field':
          return [selectedDatefield.value]
        default:
          return option.title === selectedDisplayMode.value[0] ? [selectedDisplayMode.value[1]] : []
      }
    }

    const optionsForDisplayMenu = computed((): MenuItem[][] => {
      return displayOptions.value.map((t) =>
        t.map((o) => ({
          title: o.title,
          type: o.type,
          options: (o.title !== 'Date Field' ? (o.options as string[]) : props.dateFields.map((d) => d.name)).filter(
            (opt) =>
              (opt !== 'NPS' && !opt.includes('Sentiment')) ||
              (opt === 'NPS' && props.hasNps) ||
              (opt.includes('Sentiment') && props.hasSentiment),
          ),
          showSelected: true,
          selected: selectedForDisplayOptions(o),
        })),
      )
    })

    const menus = computed((): Menu[] => {
      return [
        {
          name: 'DATA',
          selection: selectedDataDisplayMode.value[1],
          options: optionsForDataMenu.value.map((t) =>
            t.map((menu) => {
              return {
                ...menu,
                options: menu.options.map((option) => {
                  if (option === 'Themes' && props.queries.length < 1) {
                    return {
                      label: 'Themes',
                      value: 'Themes',
                      disabled: true,
                      tooltip: 'There are no Themes in this Analysis',
                    }
                  }

                  if (option === 'Theme Groups' && props.themeGroups.length < 1) {
                    return {
                      label: 'Theme Groups',
                      value: 'Theme Groups',
                      disabled: true,
                      tooltip: 'There are no Theme Groups in this Analysis',
                    }
                  }

                  return option
                }),
              }
            }),
          ),
        },
        {
          name: 'DISPLAY',
          selection: selectedModeOption.value?.label,
          options: optionsForDisplayMenu.value,
        },
        {
          name: 'RESOLUTION',
          selection: resolution.value,
          options: [
            [
              {
                title: 'Resolution',
                type: 'menu',
                showSelected: true,
                selected: resolution.value,
                options: RESOLUTIONS,
              },
            ],
          ],
        },
      ]
    })

    const sortedSeries = computed(() => {
      // Choose the largest series to sort by, since either slice could be empty.
      let [firstSeries, secondSeries, firstSeriesName, secondSeriesName, dashedFirst] =
        sliceTwoSeries.value.length > sliceOneSeries.value.length ?
          [sliceTwoSeries.value.slice(), sliceOneSeries.value.slice(), props.sliceTwoName, props.sliceOneName, true]
        : [sliceOneSeries.value.slice(), sliceTwoSeries.value.slice(), props.sliceOneName, props.sliceTwoName, false]

      firstSeries = sortSeries(firstSeries, seriesSort.value)

      return firstSeries.slice(0, seriesLimit.value).reduce((arr, sliceOne) => {
        const sliceTwo = secondSeries.find((s) => s.name === sliceOne.name)

        const data = [
          {
            ...sliceOne,
            name: sliceOne.name + ` (${firstSeriesName})`,
            lastPointLabel: true,
            lineStyle: dashedFirst ? 'dashed-line' : 'solid-line',
          },
        ]

        if (sliceTwo) {
          data.push({
            ...sliceTwo,
            name: sliceTwo.name + ` (${secondSeriesName})`,
            lastPointLabel: false,
            lineStyle: dashedFirst ? 'solid-line' : 'dashed-line',
          })
        }

        return arr.concat([data])
      }, [] as TrendLine[][])
    })

    const isLoading = computed(() => {
      return props.sliceOneData?.status === 'fetching' || props.sliceTwoData?.status === 'fetching'
    })

    const calculateYRange = () => {
      const displayOption = selectedModeOption.value
      const visibleSliceOne = sliceOneSeries.value.filter((series) => series.visible)
      const visibleSliceTwo = sliceTwoSeries.value.filter((series) => series.visible)
      const visibleDataset = visibleSliceOne.concat(visibleSliceTwo)

      const dataMax = getDataMax(
        visibleDataset,
        displayOption.yCapValue,
        displayOption.yMultipleOf,
        displayOption.allowEnhance,
      )
      const dataMin = getDataMin(visibleDataset, dataMax, -1 * displayOption.yCapValue, displayOption.yMultipleOf)

      yRange.value = [dataMin, dataMax]
    }

    const regroupData = (fieldName?: string) => {
      records.value = {}

      if (props.sliceOneData?.data) {
        sliceOneSeries.value = []
        processData(props.sliceOneData.data, props.fieldFrequencySliceOne, sliceOneSeries, fieldName)
      }

      if (props.sliceTwoData?.data) {
        sliceTwoSeries.value = []
        processData(props.sliceTwoData.data, props.fieldFrequencySliceTwo, sliceTwoSeries, fieldName)
      }
    }

    /**
     * If fieldName is not defined then we group by the `group__` field,
     * otherwise by the given fieldName.
     */
    const processData = (
      data: PivotData,
      fieldFrequency: FieldFrequency | null,
      series: typeof sliceOneSeries,
      fieldName?: string,
    ): void => {
      if (fieldName === undefined && selectedDataDisplayMode.value[0] === 'Field') {
        fieldName = selectedDataDisplayMode.value[1]
      }

      // Convert to v1 format if pivot v2 is being used here.
      if (store.getters.featureFlags.pivot_endpoint_v2 && selectedDataDisplayMode.value[0] === 'Field') {
        data.payload.forEach((item) => {
          for (let i = 0; i < seriesLimit.value; i++) {
            Object.keys(item).forEach((key) => {
              if (key.match(/fieldname(\d+)__/g)) {
                let num = key.match(/\d+/g)
                if (num === null) return
                item[item[key]] = item[`segment${num[0]}__`]
              }
            })
          }
        })
      }
      // exit early if the current this.fieldFrequency isn't the field frequency
      // related to the current fieldName and data (this should eventually align
      // as we watch both incoming this.data and this.fieldFrequency)
      if (fieldName) {
        const uniqueGroups = Array.from(new Set(data.payload.map((v) => v.group__)))
        if (!uniqueGroups.every((group) => fieldFrequency?.data?.payload.find((v) => v.group__ === group))) {
          return
        }
      }

      let impactGrouping = 'rto'

      const scoreColumnsMap = scoreColumns.value.length > 0 ? getScoreColumnRegroupMap(scoreColumns.value) : {}

      let metrics: Record<string, string> = {
        'frequency_cov': 'Frequency Frequency (#)',
        'coverage_doc_rto__': 'Frequency Frequency (%)',
        'NPS Category|nps__': 'Metric NPS',
        'sentiment__|positive%__': 'Metric Positive Sentiment',
        'sentiment__|negative%__': 'Metric Negative Sentiment',
        'sentiment__|mixed%__': 'Metric Mixed Sentiment',
        [`NPS Category|npsi_${impactGrouping}__`]: 'Impact On NPS',
        [`sentiment__|positive%i_${impactGrouping}__`]: 'Impact On Positive Sentiment',
        [`sentiment__|negative%i_${impactGrouping}__`]: 'Impact On Negative Sentiment',
        [`sentiment__|mixed%i_${impactGrouping}__`]: 'Impact On Mixed Sentiment',
        ...numericalFields.value.reduce(
          (obj, field) => ({
            [`${field}|mean__`]: `Numerical Field ${field}`,
            [`${field}|mean__i_${impactGrouping}__`]: `Impact On ${field}`,
            ...obj,
          }),
          {},
        ),
      }

      let skipOverall = [
        'coverage_doc_rto__',
        `NPS Category|npsi_${impactGrouping}__`,
        `sentiment__|positive%i_${impactGrouping}__`,
        `sentiment__|negative%i_${impactGrouping}__`,
        `sentiment__|mixed%i_${impactGrouping}__`,
        ...numericalFields.value.map((field) => `${field}|mean__i_${impactGrouping}__`),
      ]

      if (Object.keys(scoreColumnsMap).length > 0) {
        metrics = {
          ...metrics,
          ...scoreColumnsMap['standard'],
          ...scoreColumnsMap['impact'],
        }
        skipOverall = [...skipOverall, ...Object.keys(scoreColumnsMap['impact'])]
      }

      const groups = new Map<string, TrendLine[]>()

      for (let m of Object.values(metrics)) {
        // These are arrays only because currently the data structure expected
        // by the inner timeline widget, `sliceOneSeries`, is
        // an array. This is awkward because we do lookups on it below.
        groups.set(m, [])
      }
      // for each individual record in the payload array
      for (let v of data.payload) {
        // When viewing themes or theme groups on the overall dashboard, we need all groups
        if (!['Themes', 'Theme Groups'].includes(selectedDataDisplayMode.value[1])) {
          if (v.group__ !== props.group) {
            continue
          }
        }

        for (let k in v) {
          let metric_key = metrics[k]
          let g = groups.get(metric_key)
          if (g === undefined) continue

          if (fieldName === undefined && v.group__ === 'overall__' && skipOverall.includes(k)) {
            continue
          }

          // Now that we have our "plottable group", we need to find the
          // trend entry that matches.
          let trend_id: TrendLine['id']
          let name: TrendLine['name']
          let lineStyle: TrendLine['lineStyle']

          if (fieldName) {
            trend_id = `${metric_key}|${v[fieldName]}`
            name = `${v[fieldName]}`
            lineStyle = 'solid-line'
          } else {
            trend_id = `${metric_key}|${v.group__}`
            name = v.group__ === 'overall__' ? 'Overall' : v.group__
            lineStyle = 'solid-line'
          }

          let queryId = undefined

          if (selectedDataDisplayMode.value[1] === 'Themes') {
            queryId = Number(name.replace('q_', ''))
            name = themeNameMap.value[queryId]
          }

          let trendLine = g.find((item) => item.id === trend_id)
          if (trendLine === undefined) {
            trendLine = {
              color: '',
              id: trend_id,
              query_id: queryId,
              name: name,
              lineStyle: lineStyle,
              visible: true,
              counts: [],
              datetimes: [],
            }
            g.push(trendLine)
          }

          // We don't get coverage relative to the group from the pivot endpoint, it has to be calculated manually
          if (fieldName && props.group !== 'overall__' && k === 'coverage_doc_rto__' && fieldFrequency?.data) {
            const dataDisplay = selectedDataDisplayMode.value[1]

            // Find corresponding data in fieldFrequency
            const datum = fieldFrequency?.data?.payload.find(
              (d) => d[selectedDatefield.value] === v[selectedDatefield.value] && d.group__ === v.group__,
            )

            if (!datum) continue

            // Sum frequencies to get a total for the field
            const sum = Object.entries(datum).reduce(
              (sum, [key, value]) => (key.startsWith(dataDisplay + '|') ? sum + (value as number) : sum),
              0,
            )

            // Numerical types are formatted as a float, e.g 1 is "category|1.0"
            const schemaRow = props.schema.find(({ name }) => name === dataDisplay)
            if (['NUMBER', 'NPS'].includes(schemaRow?.typename ?? '')) name += '.0'

            // Calculate fraction of the total
            const fraction = (datum[`${dataDisplay}|${name}`] as number) / sum || 0
            trendLine.counts.push(fraction)
          } else if (k.includes('box%__')) {
            // We need to do this since the box%__ are already multiplied by 100,
            // unlike other percentages, and the value we need here should be in '0.2%' format.
            trendLine.counts.push((Number(v[k]) / 100) as number)
          } else {
            trendLine.counts.push(v[k] as number)
          }

          let d = Date.parse(v[selectedDatefield.value] as string)
          trendLine.datetimes.push(d)
        }

        // Gather frequency stats for tooltips

        const key =
          ['Themes', 'Theme Groups'].includes(selectedDataDisplayMode.value[1]) ?
            v.group__
          : (v[selectedDataDisplayMode.value[1]] as string)

        const dateField = selectedDatefield.value

        const overallTotal = data.payload
          .filter((d) => d.group__ === 'overall__' && d[dateField] === v[dateField])
          .reduce((total, d) => total + (d.frequency_cov as number), 0)

        if (!records.value[key]) records.value[key] = {}

        records.value[key][v[dateField] as string] = {
          countDocumentFraction: (v['frequency_cov'] as number) / overallTotal,
          countDocument: v['frequency_cov'],
        }
      }

      for (let series of groups.values()) {
        let i = 0
        for (const v of series) {
          if (v.name === 'Overall') {
            v.color = overallNPSColor
          } else {
            v.color = DrawUtils.dashboardColourPalette[i % DrawUtils.dashboardColourPalette.length]
            i += 1
          }
        }
      }

      let scoreNamesMap: Record<string, string> = {}
      scoreColumns.value.forEach((col) => (scoreNamesMap[col.name] = `${col.aggregation.title} ${col.name}`))
      const displayModeKey = [
        selectedDisplayMode.value[0],
        Object.keys(scoreNamesMap).includes(selectedDisplayMode.value[1]) ?
          scoreNamesMap[selectedDisplayMode.value[1]]
        : selectedDisplayMode.value[1],
      ].join(' ')
      const groupData = groups.get(displayModeKey)
      if (!groupData) return

      series.value = groupData.filter((d) => d.name !== '(No Value)')

      calculateYRange()
    }

    const updateConfig = () => {
      const options: NonNullable<typeof props.config>['options'] = {
        dataDisplayMode: selectedDataDisplayMode.value,
        displayMode: selectedDisplayMode.value,
        dateField: selectedDatefield.value,
        resolution: resolution.value,
        seriesLimit: seriesLimit.value,
        seriesSort: seriesSort.value,
      }
      const updated = Object.assign({}, props.config, { options })
      emit('config-changed', updated)
    }

    const getTrendEl = () => {
      return root.value?.$el.querySelector('div.content')
    }

    const getExportConfig = () => {
      return {
        dims: getTrendEl()?.getBoundingClientRect(),
        css: `
          text {
            color: #383838;
            font-size: 14px;
            stroke: none;
          }
          .line {
            stroke-width: 2px;
          }
          .axis path, .axis line {
            shape-rendering: crispEdges;
            stroke: #ebebeb;
            stroke-width: 2px;
            opacity: 0.5;
          }
        `,
      }
    }

    const getCsvData = () => {
      //  Marshall the series data into a date-based structure:
      //    { 'Thu Jan 01 2015 00:00:00' : { 'customer service ': 1, 'issues': 4 }, ...}
      const isThemes = selectedDataDisplayMode.value[1] === 'Themes'

      const dataByDate: { [key: number]: any } = {}
      const dates: number[] = []
      sliceOneSeries.value.forEach((s) => {
        const sliceTwo = sliceTwoSeries.value.find((s2) => s2.name === s.name)
        if (!sliceTwo) return
        s.datetimes.forEach((d: number, j: number) => {
          const s2Date = sliceTwo.datetimes[j]
          if (d !== s2Date) return
          if (!dataByDate[d]) {
            dataByDate[d] = {}
            dates.push(d)
          }
          const id = s.query_id ?? s.name
          dataByDate[d][id] = s.counts[j]
          dataByDate[d][id] = sliceTwo.counts[j]
        })
      })

      // Generate exhaustive lists of dates & series names
      dates.sort((a, b) => a - b)
      const seriesNames = sliceOneSeries.value.map((series) => series.query_id ?? series.name)
      seriesNames.sort()

      return dates.map((d) => {
        // Ugly way to print out the right date but without any timezone info
        // (JS thinks every date created is relative to local timezone).
        const dateString = dayjs(new Date(d)).format('YYYY-MM-DDT00:00:00.000') + 'Z'
        const row = {
          [selectedDatefield.value]: dateString,
        }
        seriesNames.forEach((id) => {
          const name = isThemes ? themeNameMap.value[+id] : id
          let s1Name = name + ` (${props.sliceOneName})`
          let s2Name = name + ` (${props.sliceTwoName})`

          if (isThemes && themeToGroupNameMap.value[+id]) {
            s1Name += ` [${themeToGroupNameMap.value[+id]}]`
            s2Name += ` [${themeToGroupNameMap.value[+id]}]`
          }

          row[s1Name] = dataByDate[d][id] ?? ''
          row[s2Name] = dataByDate[d][id] ?? ''
        })
        return row
      })
    }

    const setChartDimensions = (_: unknown, height: number): void => {
      if (props.isZoomed) {
        const height_num = height - 200
        chartHeight.value = `${height_num < 320 ? 320 : height_num}px`
      } else {
        chartHeight.value = '160px'
      }
    }

    const setDate = ([, value]: [string, string]) => {
      // Only trigger redraw for an actual change
      if (selectedDatefield.value !== value) {
        selectedDatefield.value = value
      }
    }

    const setMenuSelection = (menu: string, value: [string, string]) => {
      if (menu === 'DATA') {
        selectedDataDisplayMode.value = value
        analytics?.track.timeline.changeData(value[0], value[1])
      } else if (menu === 'DISPLAY') {
        if (value[0] === 'Date Field') {
          setDate(value)
        } else {
          // If the selected col is part of score columns, remove the aggregation title from displayMode[1],
          // This is done to separate agg info from score column name which will be saved in the dashboard config.
          let scoreColLabelsMap: Record<string, string> = {}
          scoreColumns.value.forEach((col) => (scoreColLabelsMap[`${col.name} (${col.aggregation.title})`] = col.name))
          value[1] = Object.keys(scoreColLabelsMap).includes(value[1]) ? scoreColLabelsMap[value[1]] : value[1]
          selectedDisplayMode.value = value
        }
        analytics?.track.timeline.changeDisplay(value[0], value[1])
      } else if (menu === 'RESOLUTION') {
        resolution.value = value[1] as Resolution
        analytics?.track.timeline.changeResolution(value[1])
      }
      updateConfig()
    }

    const chunkArray = <T,>(arr: T[], size: number) => {
      const chunks = []
      for (let i = 0; i < arr.length; i += size) {
        chunks.push(arr.slice(i, i + size))
      }
      return chunks
    }

    const makePptSlide = (pptx: PptxGenJS) => {
      const slide = pptx.addSlide()

      const label =
        selectedDisplayMode.value[0] === 'Impact On' ?
          `Impact on ${selectedDisplayMode.value[1]}`
        : selectedDisplayMode.value[1]

      const isThemes = selectedDataDisplayMode.value[1] === 'Themes'

      makeTimelineSlide(
        pptx,
        slide,
        [sliceOneSeries.value, sliceTwoSeries.value].flatMap((arr, slice) => {
          return arr.map((s) => {
            let name = s.name + (slice ? ` (${props.sliceTwoName})` : ` (${props.sliceOneName})`)
            if (isThemes && themeToGroupNameMap.value[s.query_id!]) {
              name += ` [${themeToGroupNameMap.value[s.query_id!]}]`
            }
            return {
              ...s,
              color: slice ? adjustColor(s.color, 60) : s.color,
              name,
            }
          })
        }),
        props.exportName + ` - Compare Timeline (${selectedDataDisplayMode.value[1]})`,
        label,
        props.dayFirstDates,
      )
    }

    watch(
      () => props.config,
      () => {
        setOptionsFromConfig()
      },
      {
        deep: true,
      },
    )

    watch(
      () => [selectedDatefield, resolution],
      () => {
        fetchData()
      },
      {
        deep: true,
      },
    )

    watch(
      selectedDisplayMode,
      () => {
        fetchData()
        regroupData()
      },
      {
        deep: true,
      },
    )

    watch(
      () => [props.queries, props.sliceOneFilters, props.sliceTwoFilters],
      () => {
        fetchData()
      },
      {
        deep: true,
      },
    )

    /**
     * When this data prop or loaded data (fieldFrequency) is changed,
     * we recalculate all the plottable series
     * that will be available to select in the UI. When those options are selected,
     * that code will simply assign the plottable data series to the inner timeline
     * widget.
     */
    watch(
      () => [
        props.sliceOneData?.data,
        props.sliceTwoData?.data,
        props.fieldFrequencySliceOne,
        props.fieldFrequencySliceTwo,
      ],
      () => {
        if (selectedDataDisplayMode.value[0] === 'Other') {
          regroupData()
        } else if (selectedDataDisplayMode.value[0] === 'Field') {
          if (props.fieldFrequencySliceOne?.status !== 'done' || props.fieldFrequencySliceTwo?.status !== 'done') return
          regroupData(selectedDataDisplayMode.value[1])
        } else {
          throw new Error('Unhandled type of data')
        }
      },
      {
        deep: true,
      },
    )

    watch(
      () => [seriesLimit.value, seriesSort.value],
      () => {
        updateConfig()
      },
    )

    onMounted(() => {
      setOptionsFromConfig()

      // This watch is set programmatically because we don't want the
      // modifications above to trigger `fetchData`.
      watch(selectedDataDisplayMode, () => fetchData())

      // This widget is remounted when entering the zoomed view.
      // this.data will be cached and unchanged, which means its watcher
      // won't be called, so we have to call regroupData manually instead
      // to populate sliceOneSeries.
      if (props.sliceOneData?.data || props.sliceTwoData?.data) {
        // regroupData(props.sliceOneData.data)
        regroupData()
      } else {
        fetchData()
      }
    })

    return {
      refresh,
      contact,
      reload,
      icon,
      errorIcon,
      selectedModeOption,
      resolution,
      calculateYRange,
      updateConfig,
      root,
      getExportConfig,
      getCsvData,
      menus,
      setChartDimensions,
      setMenuSelection,
      getTrendEl,
      yRange,
      sliceOneSeries,
      selectedDatefield,
      records,
      chartHeight,
      sortedSeries,
      chunkArray,
      isLoading,
      seriesLimit,
      sortOptions,
      seriesSort,
      makePptSlide,
      seriesLabels,
      getSeriesName,
      getSeriesTag,
      themeToGroupNameMap,
      showGroupLabels,
    }
  },
})

export default TimelineWidget
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.header-icon
  height: 32px
  width: 32px

.row
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center

.stats
  display: flex
  flex-direction: row
  justify-content: space-evenly
  align-items: center

.stat
  font-size: 26px
  min-width: 5em
  font-weight: bold
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  flex-grow: 1

.standard
  color: #068CCC

.positive
  color: rgb(33, 186, 69)

.negative
  color: rgb(238, 56, 36)

.neutral
  color: rgb(127, 127, 127)

.label
  margin: 10px
  font-size: 16px
  font-weight: normal

.error-panel
  display: flex
  flex-direction: column
  align-items: center
  font-size: 16px
  padding-bottom: 30px

.message
  display: flex
  flex-direction: row
  justify-content: center
  background-color: rgba(255, 0, 0, 0.1)
  padding: 6px
  color: $text-black
  width: 100%
  max-height: 30px
  position: absolute
  bottom: 0


.errorIcon
  position: relative
  height: 32px
  width: 32px
  display: inline-block
  top: 10px

.action
  padding-top: 20px

button
  background: none
  border: none
  border-bottom: 2px solid $blue
  padding: 3px 4px

  &:hover
    background-color: $grey-light

  &:focus
    border: 2px solid $blue-light
    outline: none

.ui.dimmer
  z-index: 5

/* Local styles */
.column.mask
  background-color: white
  opacity: 0.3
  pointer-events: none

// These settings are required to allow the chart to resize correctly. If you comment these out,
// weird things happen with the sizing of the timeline.
.timeline-container
  width: inherit
  align-items: unset

  > div
    display: flex
    margin-top: 20px
    > *
      flex-basis: 33%
      &:first-child
        .category-label
          margin-left: 48px
  ::v-deep #timeline-container
    min-width: 0

.legend
  display: flex
  justify-content: center
  color: #383838
  margin-bottom: 14px
  margin-top: 30px

  > div
    display: flex
    align-items: center
    flex: 0
    white-space: nowrap
    &:first-child
      margin-right: 30px
    > span:nth-child(1)
      display: inline-block
      width: 24px
      height: 1px
      border-top: 1px solid #999
      margin-right: 10px
    &:nth-child(2) > span:nth-child(1)
      border-top-style: dashed

.category-label
  font-size: 13px
  font-weight: bold
  margin-left: 38px

.group-tag
  margin-left: 4px

.sort-row
  display: flex
  align-items: center
  width: 100%
  margin: 10px 0

  .slice-label
    opacity: 0.4
    margin-left: 4px
    margin-bottom: 5px
</style>
